import update from 'immutability-helper';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _slice from 'lodash/slice';
import _get from 'lodash/get';
import _reject from 'lodash/reject';
import _reduce from 'lodash/reduce';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _range from 'lodash/range';
import _each from 'lodash/each';
import _size from 'lodash/size';
import _head from 'lodash/head';
import _omit from 'lodash/omit';
import typeToReducer from 'type-to-reducer';
import {decimal} from 'utilsHelper.js';
import {
    TOGGLE_BALANCE,
    ADD_OUTCOME,
    REMOVE_OUTCOME,
    CHANGE_ACTIVE_TAB,
    CHANGE_SLIP_TYPE,
    CHANGE_SLIP_STAKE,
    REFRESH_ODDS_BY_OUTCOME_ID,
    REFRESH_PREMATCH_ODDS_BY_OUTCOME_ID,
    // CALCULATE_ODDS, // for this moment not used because we should get total odds from /calculate-winning REST - 20.08.2020 DKo
    SET_BETSLIP_ERROR,
    CLEAR_BETSLIP_ERROR,
    UPDATE_POSSIBLE_WIN_DATA,
    CLEAR_BETSLIP,
    CLEAR_BETSLIP_FREEBET_AND_PROMOTION,
    LOCK_BETSLIP,
    UNLOCK_BETSLIP,
    PLACE_BETSLIP,
    PLACE_BETSLIP_AGAIN,
    TOGGLE_OUTCOME_TO_BLOCK,
    MARK_OUTCOMES_AS_IN_BLOCK,
    UNMARK_OUTCOMES_AS_IN_BLOCK,
    ADD_OUTCOMES_TO_BLOCK,
    TOGGLE_BANKER_OUTCOME,
    TOGGLE_ACCUMULATOR,
    PLACE_BETSLIP_FOR_APPROVAL,
    UPDATE_COMBINATION_TYPES,
    CHANGE_COMBINATION_TYPE,
    OUTCOME_UNAVAILABLE,
    OUTCOME_CHANGED,
    ACCEPT_HIGHER_ODDS,
    ACCEPT_DEFAULT_ODDS,
    ACCEPT_ANY_ODDS,
    REMOVE_OUTCOME_FROM_BLOCK,
    UDPATE_EXISTING_BLOCK,
    UPDATE_TOTAL_BONUS_DATA,
    SET_PREDEFINIED_STAKE,
    UPDATE_PROPOSITION_OFFERS,
    UPDATE_PLACED_BET_DETAILS
} from 'betSlipActions.js';

import {
    UPDATE_APPROVAL_DATA,
    BIND_APPROVAL_BUTTONS,
    REJECT_APPROVAL_DATA,
    ACCEPT_APPROVAL_DATA,
    SHOW_APPROVAL_MESSAGE,
    HIDE_APPROVAL_MESSAGE
} from 'approvalActions.js';

import {TOGGLE_FREEBET, SET_FREEBETS, SET_VALID_FREEBETS} from 'freebetActions.js';
import {SET_CUSTOMER_BETSLIP_PROMOTIONS, TOGGLE_PROMOTION} from 'offersActions.js';

const betSlipSystemLettersCount = process.env.BETSLIP_SYSTEM_MAX_BLOCK_COUNT && JSON.parse(process.env.BETSLIP_SYSTEM_MAX_BLOCK_COUNT);
const acceptAnyOdds = (process.env.DEFAULT_ACCEPT_ANY_ODDS && JSON.parse(process.env.DEFAULT_ACCEPT_ANY_ODDS)) ?? false;
const acceptHigherOdds = (process.env.DEFAULT_ACCEPT_HIGHER_ODDS && JSON.parse(process.env.DEFAULT_ACCEPT_HIGHER_ODDS)) ?? false;

let initialState = {
    bonusBet: false,
    balanceId: 0,
    locked: false,
    activeTab: 1,
    approvalData: null,
    freebets: [],
    acceptAnyOdds,
    acceptHigherOdds,
    showPlaceToRiskButtons: false,
    showApprovalButtons: false,
    promotions: null,

};

const stake = decimal(process.env.DEFAULT_STAKE);
const defaultType = {type: 0, name: "betSlip_type_ACCUMULATOR", key: "ACCUMULATOR", extendedName: "ACCUMULATOR"}; // ?? null

const slipModel = {
    predefiniedStake: null,
    error: null,
    approvalMessage: null,
    outcomes: [],
    blocks: [],
    winning: 0,
    tax: null,
    totalStake: 0,
    placed: false,
    addAccumulator: false,
    stake: stake,
    // odds: 0, // for this moment not used because we should get total odds from /calculate-winning REST - 20.08.2020 - DKo
    totalOdds: 0,
    totalOddsWithoutSalesTaxFactor: 0,
    winningWithoutTax: 0,
    slipType: 'ACCUMULATOR',
    type: defaultType,
    types: [],
    betInShop: null,
    bonus: 0,
    quickBet: false,
    quickBetOutcomes: [],
    freebet: null,
    validFreebets: [],
    totalBonus: null,
    propositionOffers: 0,
    placedBetDetails: {},
    selectedPromotion: null,
};
const betSlipsCount = Number(process.env.BETSLIPS_COUNT) + 1;
const betSlips = _reduce(_range(1, betSlipsCount), (total, current) => {
    total[current] = {...slipModel};
    return total;
}, {});

initialState['betSlips'] = {...betSlips};
const betSlipInitialState = {...initialState};

const betSlipReducer = typeToReducer({
    [SET_PREDEFINIED_STAKE]: (state, {payload: {activeTab, predefiniedStake}}) => {
        const predefiniedStakeFromBetslip = _get(state.betSlips, [activeTab, 'predefiniedStake']);
        let betSlips = state.betSlips;
        if(predefiniedStake == predefiniedStakeFromBetslip){
            betSlips = update(betSlips, {[activeTab]: {predefiniedStake: {$set: null}}});
        }else{
            betSlips = update(betSlips, {[activeTab]: {predefiniedStake: {$set: predefiniedStake}}});
        }
        return {...state, betSlips};
    },
    [HIDE_APPROVAL_MESSAGE]: (state, {payload: {activeTab}}) => {
        const betSlips = update(state.betSlips, {
            [activeTab]: {
                approvalMessage: {$set: null}
            }
        });
        return {...state, betSlips}
    },
    [SHOW_APPROVAL_MESSAGE]: (state, {payload: {activeTab, msg, status}}) => {
        const approvalMessageObject = {msg, status};
        const betSlips = update(state.betSlips, {
            [activeTab]: {
                approvalMessage: {$set: approvalMessageObject},
                error: {$set: null}
            }
        });
        return {...state, betSlips}
    },
    [REJECT_APPROVAL_DATA]: (state) => {
        const newState = update(state, {showApprovalButtons: {$set: false}, approvalData: {$set: null}});
        return {...newState}
    },
    [ACCEPT_APPROVAL_DATA]: (state) => {
        const newState = update(state, {showApprovalButtons: {$set: false}});
        return {...newState}
    },
    [BIND_APPROVAL_BUTTONS]: (state) => {
        let newState = update(state, {
            locked: {$set: false},
            showApprovalButtons: {$set: true}
        });
        return {...newState};
    },
    [UPDATE_APPROVAL_DATA]: (state, {payload: {approvalData}}) => {
        let newState = update(state, {approvalData: {$set: approvalData}});
        return {...newState};
    },
    [ACCEPT_DEFAULT_ODDS]: (state) => {
        return update(state, {acceptAnyOdds: {$set: false}, acceptHigherOdds: {$set: false}});
    },
    [ACCEPT_HIGHER_ODDS]: (state) => {
        const acceptHigherOdds = state.acceptHigherOdds;
        let newState = update(state, {$toggle: ['acceptHigherOdds']});
        if (!acceptHigherOdds) {
            newState = update(newState, {acceptAnyOdds: {$set: false}});
        }
        return {...newState};
    },
    [ACCEPT_ANY_ODDS]: (state) => {
        const acceptAnyOdds = state.acceptAnyOdds;
        let newState = update(state, {$toggle: ['acceptAnyOdds']});
        if (!acceptAnyOdds) {
            newState = update(newState, {acceptHigherOdds: {$set: false}});
        }
        return {...newState};
    },
    [PLACE_BETSLIP_FOR_APPROVAL]: {
        PENDING: (state) => {
            const newState = update(state, {locked: {$set: true}});
            return {...newState};
        },
        SUCCESS: (state, {payload: {activeTab, approvalData}}) => {
            approvalData['creationDate'] = new Date().getTime();
            const newState = update(state, {locked: {$set: true}, approvalData: {$set: approvalData}});
            const betSlips = update(newState.betSlips, {
                [activeTab]: {
                    outcomes: {
                        $apply: function (outcomes) {
                            return _map(outcomes, (o) => {
                                return _omit(o, ['unavailable', 'oddsChanged', 'prevOdds'])
                            })
                        }
                    },
                    blocks: {
                        $apply: function (b) {
                            return _map(b, (block) => {
                                block['outcomes'] = _map(block['outcomes'], (o) => {
                                    return _omit(o, ['unavailable', 'oddsChanged', 'prevOdds'])
                                });
                                return block;
                            })
                        }
                    }
                }
            });
            return {...newState, betSlips};
        },
        FAILURE: (state) => {
            const newState = update(state, {
                locked: {$set: false}
            });
            return {...newState};
        }
    },
    [SET_FREEBETS]: (state, {payload: {freebets}}) => {
        const newState = update(state, {freebets: {$set: freebets}});
        return {...newState};
    },
    [SET_VALID_FREEBETS]: (state, {payload: {activeTab, validFreebets}}) => {
        const betSlips = update(state.betSlips, {[activeTab]: {validFreebets: {$set: validFreebets}}});
        return {...state, betSlips};
    },
    [TOGGLE_FREEBET]: (state, {payload: {activeTab, freebet}}) => {
        const {freebetStake, offerFreebetId} = freebet;
        const selectedFreebet = _get(state.betSlips, [activeTab, 'freebet']);
        let betSlips = state.betSlips;

        if (_size(selectedFreebet) && (offerFreebetId == _get(selectedFreebet, ['offerFreebetId']))) {
            betSlips = update(betSlips, {[activeTab]: {stake: {$set: stake}, freebet: {$set: null}}});
        } else {
            betSlips = update(betSlips, {[activeTab]: {
                stake: {$set: freebetStake},
                freebet: {$set: freebet},
                selectedPromotion: {$set: null},
            }});
        }

        return {...state, betSlips};
    },
    [OUTCOME_UNAVAILABLE]: (state, {payload: {activeTab, outcomeId}}) => {

        outcomeId = Number(outcomeId);

        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const outcomeInBlock = _get(outcomesOnTab, [indexOfItem, 'inBlock']);
        const blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);
        let betSlips = state.betSlips;
        if (outcomeInBlock) {
            const indexOfBlock = _findIndex(blocksOnTab, ({outcomes}) => {
                return (_findIndex(outcomes, {outcomeId}) != -1);
            });
            const block = _head(_slice(blocksOnTab, indexOfBlock, indexOfBlock + 1));
            const outcomesInBlock = _get(block, 'outcomes');
            const indexOfOutcomeInBlock = _findIndex(outcomesInBlock, {outcomeId});

            betSlips = update(betSlips, {
                [activeTab]: {
                    blocks: {
                        [indexOfBlock]: {
                            outcomes: {
                                [indexOfOutcomeInBlock]: {
                                    unavailable: (unavailable) => {
                                        return update(unavailable || false, {$set: !unavailable})
                                    }
                                }
                            }
                        }
                    }
                }
            })
        } else {
            betSlips = update(betSlips, {
                [activeTab]: {
                    outcomes: {
                        [indexOfItem]: {
                            unavailable: (unavailable) => {
                                return update(unavailable || false, {$set: !unavailable})
                            }
                        }
                    }
                }
            });
        }

        return {...state, betSlips};
    },
    [OUTCOME_CHANGED]: (state, {payload: {activeTab, outcomeId, outcomeOddsFromUi, databaseOdds}}) => {

        outcomeId = Number(outcomeId);

        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const outcomeInBlock = _get(outcomesOnTab, [indexOfItem, 'inBlock']);
        const blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);
        let betSlips = state.betSlips;
        if (outcomeInBlock) {
            const indexOfBlock = _findIndex(blocksOnTab, ({outcomes}) => {
                return (_findIndex(outcomes, {outcomeId}) != -1);
            });
            const block = _head(_slice(blocksOnTab, indexOfBlock, indexOfBlock + 1));
            const outcomesInBlock = _get(block, 'outcomes');
            const indexOfOutcomeInBlock = _findIndex(outcomesInBlock, {outcomeId});

            betSlips = update(betSlips, {
                [activeTab]: {
                    blocks: {
                        [indexOfBlock]: {
                            outcomes: {
                                [indexOfOutcomeInBlock]: {
                                    outcomeOdds: {$set: databaseOdds},
                                    prevOdds: {$set: outcomeOddsFromUi},
                                    oddsChanged: (oddsChanged) => {
                                        return update(oddsChanged || false, {$set: !oddsChanged})
                                    }
                                }
                            }
                        }
                    }
                }
            })
        } else {
            betSlips = update(betSlips, {
                [activeTab]: {
                    outcomes: {
                        [indexOfItem]: {
                            outcomeOdds: {$set: databaseOdds},
                            prevOdds: {$set: outcomeOddsFromUi},
                            oddsChanged: (oddsChanged) => {
                                return update(oddsChanged || false, {$set: !oddsChanged})
                            }
                        }
                    }
                }
            });
        }

        return {...state, betSlips};
    },
    [TOGGLE_ACCUMULATOR]: (state, {payload: {activeTab}}) => {
        const betSlips = update(state.betSlips, {[activeTab]: {$toggle: ['addAccumulator']}});
        return {...state, betSlips};
    },
    [TOGGLE_BANKER_OUTCOME]: (state, {payload: {activeTab, outcomeId}}) => {
        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const betSlips = update(state.betSlips, {[activeTab]: {outcomes: {[indexOfItem]: {$toggle: ['banker']}}}});
        return {...state, betSlips};
    },
    [ADD_OUTCOMES_TO_BLOCK]: (state, {payload: {activeTab, selectedOutcomes}}) => {
        const blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);
        const blocksCount = _size(blocksOnTab);
        const newBlock = {
            blockOrder: blocksCount + 1,
            outcomes: selectedOutcomes
        };
        const betSlips = update(state.betSlips, {[activeTab]: {blocks: {$push: [newBlock]}}});
        return {...state, betSlips};
    },
    [UDPATE_EXISTING_BLOCK]: (state, {payload: {activeTab, firstOutcomeIdFromBlock, outcomeId}}) => {
        const blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);
        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const outcome = _head(_slice(outcomesOnTab, indexOfItem, indexOfItem + 1));
        const indexOfBlock = _findIndex(blocksOnTab, ({outcomes}) => {
            return (_findIndex(outcomes, {outcomeId: firstOutcomeIdFromBlock}) != -1);
        });

        let betSlips = update(state.betSlips, {
            [activeTab]: {
                blocks: {
                    [indexOfBlock]: {
                        outcomes: {$push: [outcome]}
                    }
                },
                outcomes: {
                    [indexOfItem]: {$toggle: ['inBlock']}
                }
            }
        });
        return {...state, betSlips};
    },
    [MARK_OUTCOMES_AS_IN_BLOCK]: (state, {payload: {activeTab, outcomesIdsMarkedAsInBlock}}) => {
        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        let betSlips = null;
        _each(outcomesIdsMarkedAsInBlock, (outcomeId) => {
            const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
            betSlips = update(betSlips || state.betSlips, {[activeTab]: {outcomes: {[indexOfItem]: {$toggle: ['selected', 'inBlock']}}}});
        });

        return {...state, betSlips};
    },
    [UNMARK_OUTCOMES_AS_IN_BLOCK]: (state, {payload: {activeTab, outcomesIdsUnMarkedAsInBlock}}) => {
        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        let betSlips = null;
        _each(outcomesIdsUnMarkedAsInBlock, (outcomeId) => {
            const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
            betSlips = update(betSlips || state.betSlips, {[activeTab]: {outcomes: {[indexOfItem]: {$toggle: ['inBlock']}}}});
        });

        return {...state, betSlips};
    },
    [REMOVE_OUTCOME_FROM_BLOCK]: (state, {payload: {activeTab, outcomeId}}) => {

        // DRY - REMOVE_OUTCOME ?

        outcomeId = Number(outcomeId);

        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const isOutcomeInBlock = _get(outcomesOnTab, [indexOfItem, 'inBlock']);
        const blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);
        const indexOfBlock = _findIndex(blocksOnTab, ({outcomes}) => {
            return (_findIndex(outcomes, {outcomeId}) != -1);
        });
        const block = _head(_slice(blocksOnTab, indexOfBlock, indexOfBlock + 1));
        const outcomesInBlock = _get(block, 'outcomes');
        const indexOfOutcomeInBlock = _findIndex(outcomesInBlock, {outcomeId});

        let betSlips = state.betSlips;

        if (indexOfBlock != -1 && isOutcomeInBlock) {
            betSlips = update(betSlips, {
                [activeTab]: {
                    blocks: {
                        [indexOfBlock]: {
                            outcomes: {$splice: [[indexOfOutcomeInBlock, 1]]}
                        }
                    },
                    outcomes: {
                        [indexOfItem]: {$toggle: ['inBlock']}
                    }
                }
            });
        }

        if (_size(outcomesInBlock) == 2) {
            const secondOutcomeInBlock = _head(_reject(outcomesInBlock, {outcomeId}));
            const outcomeIdOfSecondOutcome = _get(secondOutcomeInBlock, 'outcomeId');
            const indexOfItemInOutcomes = _findIndex(outcomesOnTab, {outcomeId: outcomeIdOfSecondOutcome});
            betSlips = update(betSlips, {
                [activeTab]: {
                    outcomes: {[indexOfItemInOutcomes]: {$toggle: ['inBlock']}},
                    blocks: {$splice: [[indexOfBlock, 1]]}
                }
            });
            
        }

        return {...state, betSlips};
    },
    [TOGGLE_OUTCOME_TO_BLOCK]: (state, {payload: {activeTab, outcomeId}}) => {

        outcomeId = Number(outcomeId);

        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const betSlips = update(state.betSlips, {
            [activeTab]: {
                outcomes: {
                    [indexOfItem]: {
                        selected: (selected) => {
                            return update(selected || false, {$set: !selected})
                        }
                    }
                }
            }
        });
        return {...state, betSlips};
    },
    [PLACE_BETSLIP_AGAIN]: (state, {payload: {activeTab}}) => {

        const slipType = _get(state.betSlips, [activeTab, 'slipType']);
        const approvalData = {...state.approvalData};
        const {status = null, stake = null, oddsChanges = null} = approvalData;

        let betSlips = update(state.betSlips, {
            [activeTab]: {
                placed: {$set: false},
                betInShop: {$set: false},
                approvalMessage: {$set: null},
                outcomes: {
                    $apply: function (outcomes) {
                        return _map(outcomes, (o) => {
                            return _omit(o, ['unavailable', 'oddsChanged', 'prevOdds'])
                        })
                    }
                },
                blocks: {
                    $apply: function (b) {
                        return _map(b, (block) => {
                            block['outcomes'] = _map(block['outcomes'], (o) => {
                                return _omit(o, ['unavailable', 'oddsChanged', 'prevOdds'])
                            });
                            return block;
                        })
                    }
                }
            }
        });

        if (status && [2, 4].indexOf(status) != -1 && stake && slipType == 'ACCUMULATOR') {
            betSlips = update(betSlips, {
                [activeTab]: {
                    stake: {$set: stake}
                }
            })
        }

        if (status && [3, 4].indexOf(status) != -1 && oddsChanges) {
            const oddsChangesParts = _compact(oddsChanges.split('|'));
            const preparedOddsChange = _reduce(oddsChangesParts, (initialObject, nextElement) => {
                const [outcomeId, prevOdds, outcomeOdds] = nextElement.split('_');
                return {...initialObject, [outcomeId]: {prevOdds, outcomeOdds}};
            }, {});

            betSlips = update(betSlips, {
                [activeTab]: {
                    outcomes: {
                        $apply: function (outcomes) {
                            return _map(outcomes, (o) => {
                                const {outcomeId} = o;
                                if ((outcomeId in preparedOddsChange)) {
                                    const outcomeOdds = _get(preparedOddsChange, [outcomeId, 'prevOdds']);
                                    return {...o, outcomeOdds}
                                }
                                return o;
                            });
                        }
                    },
                    blocks: {
                        $apply: function (b) {
                            return _map(b, (block) => {
                                block['outcomes'] = _map(block['outcomes'], (o) => {
                                    const {outcomeId} = o;
                                    if ((outcomeId in preparedOddsChange)) {
                                        const outcomeOdds = _get(preparedOddsChange, [outcomeId, 'prevOdds']);
                                        return {...o, outcomeOdds}
                                    }
                                    return o;
                                });
                                return block;
                            })
                        }
                    }
                }
            });
        }

        const newState = update(state, {approvalData: {$set: null}});
        return {...newState, betSlips};
    },
    [PLACE_BETSLIP]: {
        PENDING: (state) => {
            const newState = update(state, {locked: {$set: true}});
            return {...newState};
        },
        SUCCESS: (state, {payload: {data, activeTab}}) => {
            const {value, slipId} = data;
            const freebet = _get(state.betSlips, [activeTab, 'freebet']);
            const selectedPromotion = _get(state.betSlips, [activeTab, 'selectedPromotion']);
            let newState = update(state, {locked: {$set: false}});
            
            if (_size(freebet)) {
                const {offerFreebetId} = freebet;
                const freebets = state.freebets;
                const index = _findIndex(freebets, {offerFreebetId});
                if (index != -1) {
                    newState = update(newState, {freebets: {$splice: [[index, 1]]}});
                }
            }

            if (_size(selectedPromotion)) {
                const {offerId} = selectedPromotion;
                const promotions = state.promotions;
                const index = _findIndex(promotions, (promotion) => promotion.offerId == offerId); 
                if (index) {
                    newState = update(newState, {promotions: {$splice: [[index, 1]]}});
                }
            }

            let betSlips = update(state.betSlips, {
                [activeTab]: {
                    error: {$set: null},
                    placed: {$set: true},
                    freebet: {$set: null},
                    validFreebets: {$set: null},
                    selectedPromotion: {$set: null},
                }
            });

            if(slipId){
                betSlips = update(betSlips, {[activeTab]: {slipId: {$set: slipId}}});
            }
            if (value) {
                betSlips = update(betSlips, {[activeTab]: {betInShop: {$set: value}}});
            }
            return {...newState, betSlips};
        },
        FAILURE: (state, {payload: {activeTab}}) => {
            const newState = update(state, {
                locked: {$set: false}
            });

            const betSlips = update(state.betSlips, {
                [activeTab]: {
                    placed: {$set: false},
                    outcomes: {
                        $apply: function (outcomes) {
                            return _map(outcomes, (o) => {
                                return _omit(o, ['unavailable', 'oddsChanged'])
                            })
                        }
                    },
                    blocks: {
                        $apply: function (b) {
                            return _map(b, (block) => {
                                block['outcomes'] = _map(block['outcomes'], (o) => {
                                    return _omit(o, ['unavailable', 'oddsChanged'])
                                });
                                return block;
                            })
                        }
                    }
                }
            });

            return {...newState, betSlips};
        }
    },
    [LOCK_BETSLIP]: (state) => {
        const newState = update(state, {locked: {$set: true}});
        return {...newState};
    },
    [UNLOCK_BETSLIP]: (state) => {
        const newState = update(state, {locked: {$set: false}});
        return {...newState};
    },
    [TOGGLE_BALANCE]: (state, {payload: {setBonusBalance, balanceId}}) => {

        balanceId = Number(balanceId);

        let newState = update(state, {
            balanceId: {$set: balanceId},
            bonusBet: {$set: setBonusBalance}
        });

        return newState;
    },
    [ADD_OUTCOME]: (state, {payload: {outcome, activeTab}}) => {
        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        outcome.order = _size(outcomesOnTab) + 1;

        const betSlips = update(state.betSlips, {
            [activeTab]: {
                outcomes: {$push: [outcome]},
                approvalMessage: {$set: null},
                placed: {$set: false}
            }
        });
        return {...state, betSlips};
    },
    [REMOVE_OUTCOME]: (state, {payload: {outcomeId, activeTab}}) => {

        outcomeId = Number(outcomeId);

        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);
        const slipType = _get(state.betSlips, [activeTab, 'slipType']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const outcome = _head(_slice(outcomesOnTab, indexOfItem, indexOfItem + 1));
        let betSlips = state.betSlips;

        if (outcome.inBlock) {
            const indexOfBlock = _findIndex(blocksOnTab, ({outcomes}) => {
                return (_findIndex(outcomes, {outcomeId}) != -1);
            });
            const block = _head(_slice(blocksOnTab, indexOfBlock, indexOfBlock + 1));
            const outcomesInBlock = _get(block, 'outcomes');
            const indexOfOutcomeInBlock = _findIndex(outcomesInBlock, {outcomeId});
            if (indexOfBlock != -1) {
                // removes outcome from block only
                betSlips = update(betSlips, {
                    [activeTab]: {
                        blocks: {
                            [indexOfBlock]: {
                                outcomes: {$splice: [[indexOfOutcomeInBlock, 1]]}
                            }
                        },
                        outcomes: {
                            [indexOfItem]: {$toggle: ['inBlock']},
                        }
                    }
                });
                
                // switches banker
                if (betSlipSystemLettersCount) {
                    const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
                    const blocksOnTab = _get(betSlips, [activeTab, 'blocks']);
                    const outcomesNotInBlock = _reject(outcomesOnTab, {inBlock: true});
                    const outcomesNotBanker = _reject(outcomesNotInBlock, {banker: true});
                    const groupCount = _size(blocksOnTab) + _size(outcomesNotBanker);
                    if (groupCount >= betSlipSystemLettersCount) {
                        betSlips = update(betSlips, {
                            [activeTab]: {
                                outcomes: {
                                    [indexOfItem]: {$toggle: ['banker']}
                                }
                            }
                        });
                    }
                }
                // clears block if the second to last outcome is removed
                if (_size(outcomesInBlock) == 2) {
                    const secondOutcomeInBlock = _head(_reject(outcomesInBlock, {outcomeId}));
                    const outcomeIdOfSecondOutcome = _get(secondOutcomeInBlock, 'outcomeId');
                    const indexOfItemInOutcomes = _findIndex(outcomesOnTab, {outcomeId: outcomeIdOfSecondOutcome});
                    betSlips = update(betSlips, {
                        [activeTab]: {
                            outcomes: {
                                [indexOfItemInOutcomes]: {$toggle: ['inBlock']}
                            },
                            blocks: {
                                $splice: [[indexOfBlock, 1]]}
                        }
                    });
                }
            }
        }
        if (!outcome.inBlock || (outcome.inBlock && slipType == 'ACCUMULATOR')) {
            // removes outcome from slip only
            betSlips = update(betSlips, {[activeTab]: {
                outcomes: {
                    $splice: [[indexOfItem, 1]],
                }
            }});
            betSlips = update(betSlips, {
                [activeTab]: {
                    placed: {$set: false},
                    outcomes: {
                        $apply: function (outcomes) {
                            return _map(outcomes, (o, idx) => {
                                o.order = idx + 1;
                                return o;
                            })
                        }
                    }
                }
            });
        }

        const outcomesOnTabAfterRemove = _get(betSlips, [activeTab, 'outcomes']);
        
        if (_size(outcomesOnTabAfterRemove) == 0){
            betSlips = update(betSlips, {
                [activeTab]: {
                    propositionOffers: {$set: 0}
                }
            });
        }

        return {...state, betSlips};
    },
    [CHANGE_ACTIVE_TAB]: (state, {payload: {activeTab}}) => {
        return {...state, activeTab}
    },
    [CHANGE_SLIP_TYPE]: (state, {payload: {slipType, activeTab}}) => {
        let betSlips = update(state.betSlips, {[activeTab]: {slipType: {$set: slipType}}});
        if (slipType != 'ACCUMULATOR') {
            betSlips = update(betSlips, {[activeTab]: {
                freebet: {$set: null},
                selectedPromotion: {$set: null},
            }});
        }
        return {...state, betSlips};
    },
    [UPDATE_COMBINATION_TYPES]: (state, {payload: {types, activeTab}}) => {
        const betSlips = update(state.betSlips, {[activeTab]: {types: {$set: types}}});
        return {...state, betSlips};
    },
    [CHANGE_COMBINATION_TYPE]: (state, {payload: {type, activeTab}}) => {
        const types = _get(state.betSlips, [activeTab, 'types']);
        const selectedType = _find(types, {type});
        const betSlips = update(state.betSlips, {[activeTab]: {type: {$set: selectedType}}});
        return {...state, betSlips};
    },
    [REFRESH_ODDS_BY_OUTCOME_ID]: (state, {payload: {activeTab, freshOutcomeData}}) => {
        let {outcomeId, outcomeOdds, prevOdds, active} = freshOutcomeData;

        outcomeId = Number(outcomeId);

        const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        const blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);
        const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
        const outcome = _head(_slice(outcomesOnTab, indexOfItem, indexOfItem + 1));

        let betSlips = update(state.betSlips, {
            [activeTab]: {
                outcomes: {
                    [indexOfItem]: {
                        active: {$set: active},
                        outcomeOdds: {$set: outcomeOdds},
                        prevOdds: {$set: prevOdds},
                        $unset: ['oddsChanged', 'unavailable']
                    }
                }
            }
        });

        if (outcome.inBlock) {
            const indexOfBlock = _findIndex(blocksOnTab, ({outcomes}) => {
                return (_findIndex(outcomes, {outcomeId}) != -1);
            });

            const block = _head(_slice(blocksOnTab, indexOfBlock, indexOfBlock + 1));
            const outcomesInBlock = _get(block, 'outcomes');
            const indexOfOutcomeInBlock = _findIndex(outcomesInBlock, {outcomeId});
            betSlips = update(betSlips, {
                [activeTab]: {
                    blocks: {
                        [indexOfBlock]: {
                            outcomes: {
                                [indexOfOutcomeInBlock]: {
                                    active: {$set: active},
                                    outcomeOdds: {$set: outcomeOdds},
                                    prevOdds: {$set: prevOdds},
                                    $unset: ['oddsChanged', 'unavailable']
                                }
                            }
                        }
                    }
                }
            });
        }

        return {...state, betSlips};
    },
    [REFRESH_PREMATCH_ODDS_BY_OUTCOME_ID]: (state, {payload: {activeTab, freshOutcomeData}}) => {
        let betSlips = state.betSlips;
        let outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
        let blocksOnTab = _get(state.betSlips, [activeTab, 'blocks']);

        freshOutcomeData.forEach(freshOutcome => {
            let {outcomeId, outcomeOdds, outcomeOddsWithoutFof} = freshOutcome;

            outcomeId = Number(outcomeId);

            const indexOfItem = _findIndex(outcomesOnTab, {outcomeId});
            const outcome = _head(_slice(outcomesOnTab, indexOfItem, indexOfItem + 1));

            betSlips = update(betSlips, {
                [activeTab]: {
                    outcomes: {
                        [indexOfItem]: {
                            outcomeOdds: {$set: outcomeOdds},
                            outcomeOddsWithoutFof: {$set: outcomeOddsWithoutFof},
                        }
                    }
                }
            });

            if (outcome.inBlock) {
                const indexOfBlock = _findIndex(blocksOnTab, ({outcomes}) => {
                    return (_findIndex(outcomes, {outcomeId}) != -1);
                });

                const block = _head(_slice(blocksOnTab, indexOfBlock, indexOfBlock + 1));
                const outcomesInBlock = _get(block, 'outcomes');
                const indexOfOutcomeInBlock = _findIndex(outcomesInBlock, {outcomeId});
                betSlips = update(betSlips, {
                    [activeTab]: {
                        blocks: {
                            [indexOfBlock]: {
                                outcomes: {
                                    [indexOfOutcomeInBlock]: {
                                        outcomeOdds: {$set: outcomeOdds},
                                    }
                                }
                            }
                        }
                    }
                });
            }
        })

        return {...state, betSlips};
    },
    [CHANGE_SLIP_STAKE]: (state, {payload: {stake, activeTab}}) => {
        const betSlips = update(state.betSlips, {[activeTab]: {stake: {$set: stake}}});
        return {...state, betSlips};
    },
    // for this moment not used because we should get total odds from /calculate-winning REST - 20.08.2020 - DKo
    // [CALCULATE_ODDS]: (state, {payload: {activeTab}}) => {
    //     const outcomesOnTab = _get(state.betSlips, [activeTab, 'outcomes']);
    //     const outcomesOdds = _map(outcomesOnTab, ({outcomeOdds}) => Number(outcomeOdds));
    //     const odds = _reduce(outcomesOdds, (total, currentOdds) => {
    //         return parseFloat((total * currentOdds).toFixed(2));
    //     }, 1.00);

    //     const betSlips = update(state.betSlips, {[activeTab]: {odds: {$set: odds}}});
    //     return {...state, betSlips};
    // },
    [SET_BETSLIP_ERROR]: (state, {payload: {error, activeTab}}) => {
        error = [].concat(error);
        error = _map(error, String);

        const betSlips = update(state.betSlips, {[activeTab]: {error: {$set: error}, placed: {$set: false}}});
        return {...state, betSlips};
    },
    [CLEAR_BETSLIP_ERROR]: (state, {payload: {activeTab}}) => {
        const betSlips = update(state.betSlips, {[activeTab]: {error: {$set: null}}});
        return {...state, betSlips};
    },
    [UPDATE_POSSIBLE_WIN_DATA]: (state, {payload: {activeTab, possibleWinData}}) => {
        const { totalStake, winning, bonus, totalBonus = null, tax, taxFactor, amountPrecision, oddsPrecision, totalOdds = 0, totalOddsWithoutSalesTaxFactor = 0, winningWithoutTax = 0} = possibleWinData;
        const betSlips = update(state.betSlips, {
            [activeTab]: {
                winning: {$set: winning},
                totalStake: {$set: totalStake},
                bonus: {$set: bonus},
                totalBonus: {$set: totalBonus},
                tax: {$set: taxFactor},
                taxFromWinning: {$set: tax},
                totalOdds: {$set: totalOdds},
                totalOddsWithoutSalesTaxFactor: {$set: totalOddsWithoutSalesTaxFactor},
                winningWithoutTax: {$set: winningWithoutTax},
                amountPrecision: {$set: amountPrecision},
                oddsPrecision: {$set: oddsPrecision}
            }
        });
        return {...state, betSlips};
    },
    [CLEAR_BETSLIP]: (state, {payload: {activeTab}}) => {
        const newState = update(state, {locked: {$set: false}, approvalData: {$set: null}});
        const betSlips = update(newState.betSlips, {[activeTab]: {$set: {...slipModel}}});
        return {...newState, betSlips};
    },
    [CLEAR_BETSLIP_FREEBET_AND_PROMOTION]: (state) => {
        const newState = update(state, {
            freebets: {$set: []},
            promotions: {$set: null},
        });

        const betSlips = update(state.betSlips, {[state.activeTab]: {
            freebet: {$set: null},
            selectedPromotion: {$set: null},
        }});
    
        return {...newState, betSlips};
    },
    [UPDATE_TOTAL_BONUS_DATA]: (state, { payload: { activeTab, totalBonusData } }) => {
        const { currentBonusValue, futureBonusValue, isGameWithoutTax, isTotalbonusAvailable, minOutcomeOdds, outcomesLeftToPlayWithoutTax } = totalBonusData;
        const betSlips = update(state.betSlips, {
            [activeTab]: {
                totalBonus: {$set:totalBonusData},
            }
        });
        return { ...state, betSlips };
    },

    [UPDATE_PROPOSITION_OFFERS]: (state, { payload: { activeTab, propositionOffers } }) => {
        const betSlips = update(state.betSlips, {
            [activeTab]: {
                propositionOffers: {$set:propositionOffers},
            }
        });
        return { ...state, betSlips };
    },

    [UPDATE_PLACED_BET_DETAILS]: (state, { payload: { activeTab, placedBetDetails } }) => {
        const betSlips = update(state.betSlips, {
            [activeTab]: {
                placedBetDetails: {$set:placedBetDetails},
            }
        });
        return { ...state, betSlips };
    },

    [SET_CUSTOMER_BETSLIP_PROMOTIONS]: (state, {payload: {promotions}}) => {
        const newState = update(state, {promotions: {$set: promotions}});
        return {...newState};
    },
    [TOGGLE_PROMOTION]: (state, {payload: {activeTab, selectedPromotion}}) => {
        const {fixedStake, offerId} = selectedPromotion;
        const promotionOnBetSlip = _get(state.betSlips, [activeTab, 'selectedPromotion']);
        let betSlips = state.betSlips;

        if (_size(promotionOnBetSlip) && (offerId == _get(promotionOnBetSlip, ['offerId']))) {
            betSlips = update(betSlips, {[activeTab]: {
                stake: {$set: fixedStake},
                selectedPromotion: {$set: null},
            }});
        } else {
            betSlips = update(betSlips, {[activeTab]: {
                stake: {$set: fixedStake},
                selectedPromotion: {$set: selectedPromotion},
                freebet: {$set: null},
            }});
        }

        return {...state, betSlips};
    },

}, initialState);

export {betSlipReducer as default, betSlipInitialState}


