import styled from 'styled-components';

const S = {};

S.IntegrationGamesListTitle = styled.div`
    font-size: 3rem;
    font-weight: 600;
    color: #FFF200;
    text-align: center;
    margin: 1rem;
`

S.IntegrationGamesList = styled.div`
    margin: 1rem;
    display: flex;  
    flex-direction: row;
    

    & > div:first-child {
        width: 9%;
    }

    & > div:last-child {
        width: 91%;

        & > .loader {
            & > div {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
            }
        }
        
    }
`;


export default S;
