import _each from 'lodash/each';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _intersection from 'lodash/intersection';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _size from 'lodash/size';
import _debounce from 'lodash/debounce';
import _some from 'lodash/some';
import _filter from 'lodash/filter';
import _reject from 'lodash/reject';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _forEach from 'lodash/forEach';
import _camelCase from 'lodash/camelCase';
import _omit from 'lodash/omit';
import {translation, decimal} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {getBetslipTypes, getSystemBetMultiplier} from 'betslipHelper.js';
import {updateUserBalance} from 'authActions.js';
import {batch} from 'react-redux';
import {startInterval, stopInterval} from 'intervalActions.js';
import {startIntervalFetchApprovedSlips} from 'approvalActions.js';
import {checkFreetbetConditionValidity} from 'freebetActions.js';

// actions names
export const SET_PREDEFINIED_STAKE = 'SET_PREDEFINIED_STAKE';
export const TOGGLE_BALANCE = 'TOGGLE_BALANCE';
export const ADD_OUTCOME = 'ADD_OUTCOME';
export const REMOVE_OUTCOME = 'REMOVE_OUTCOME';
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';
export const CHANGE_SLIP_TYPE = 'CHANGE_SLIP_TYPE';
export const REFRESH_ODDS_BY_OUTCOME_ID = 'REFRESH_ODDS_BY_OUTCOME_ID';
export const REFRESH_PREMATCH_ODDS_BY_OUTCOME_ID = 'REFRESH_PREMATCH_ODDS_BY_OUTCOME_ID';
export const CHANGE_SLIP_STAKE = 'CHANGE_SLIP_STAKE';
// export const CALCULATE_ODDS = 'CALCULATE_ODDS';  // for this moment not used because we should get total odds from /calculate-winning REST - 20.08.2020 DKo
export const SET_BETSLIP_ERROR = 'SET_BETSLIP_ERROR';
export const CLEAR_BETSLIP_ERROR = 'CLEAR_BETSLIP_ERROR';
export const UPDATE_POSSIBLE_WIN_DATA = 'UPDATE_POSSIBLE_WIN_DATA';
export const CLEAR_BETSLIP = 'CLEAR_BETSLIP';
export const CLEAR_BETSLIP_FREEBET_AND_PROMOTION = 'CLEAR_BETSLIP_FREEBET_AND_PROMOTION';
export const LOCK_BETSLIP = 'LOCK_BETSLIP';
export const UNLOCK_BETSLIP = 'UNLOCK_BETSLIP';
export const PLACE_BETSLIP = 'PLACE_BETSLIP';
export const PLACE_BETSLIP_AGAIN = 'PLACE_BETSLIP_AGAIN';
export const TOGGLE_OUTCOME_TO_BLOCK = 'TOGGLE_OUTCOME_TO_BLOCK';
export const ADD_OUTCOMES_TO_BLOCK = 'ADD_OUTCOMES_TO_BLOCK';
export const MARK_OUTCOMES_AS_IN_BLOCK = 'MARK_OUTCOMES_AS_IN_BLOCK';
export const UNMARK_OUTCOMES_AS_IN_BLOCK = 'UNMARK_OUTCOMES_AS_IN_BLOCK';
export const TOGGLE_BANKER_OUTCOME = 'TOGGLE_BANKER_OUTCOME';
export const TOGGLE_ACCUMULATOR = 'TOGGLE_ACCUMULATOR';
export const PLACE_BETSLIP_FOR_APPROVAL = 'PLACE_BETSLIP_FOR_APPROVAL';
export const UPDATE_COMBINATION_TYPES = 'UPDATE_COMBINATION_TYPES';
export const CHANGE_COMBINATION_TYPE = 'CHANGE_COMBINATION_TYPE';
export const OUTCOME_UNAVAILABLE = 'OUTCOME_UNAVAILABLE';
export const OUTCOME_CHANGED = 'OUTCOME_CHANGED';
export const ACCEPT_HIGHER_ODDS = 'ACCEPT_HIGHER_ODDS';
export const ACCEPT_ANY_ODDS = 'ACCEPT_ANY_ODDS';
export const ACCEPT_DEFAULT_ODDS = 'ACCEPT_DEFAULT_ODDS';
export const REMOVE_OUTCOME_FROM_BLOCK = 'REMOVE_OUTCOME_FROM_BLOCK';
export const UDPATE_EXISTING_BLOCK = 'UDPATE_EXISTING_BLOCK';
export const UPDATE_TOTAL_BONUS_DATA = 'UPDATE_TOTAL_BONUS_DATA';
export const UPDATE_PROPOSITION_OFFERS = 'UPDATE_PROPOSITION_OFFERS';
export const UPDATE_PLACED_BET_DETAILS = 'UPDATE_PLACED_BET_DETAILS';

// actions creator
const setPredefiniedStake = (predefiniedStake) => {
    return {
        type: SET_PREDEFINIED_STAKE,
        payload: {predefiniedStake}
    }
};

const toggleBalance = (setBonusBalance, balanceId) => {
    return {
        type: TOGGLE_BALANCE,
        payload: {setBonusBalance, balanceId}
    }
};

const toggleAcceptAnyOdds = () => {
    return (dispatch) => {
        dispatch({type: ACCEPT_ANY_ODDS});
    }
};

const toggleAcceptHigherOdds = () => {
    return (dispatch) => {
        dispatch({type: ACCEPT_HIGHER_ODDS});
    }
};

const toggleAcceptDefaultOdds = () => {
    return (dispatch) => {
        dispatch({type: ACCEPT_DEFAULT_ODDS});
    }
};

const clearBetslip = () => {
    return {
        type: CLEAR_BETSLIP
    }
};

const refreshOddsByOutcomeId = (freshOutcomeData) => {
    return {
        type: REFRESH_ODDS_BY_OUTCOME_ID,
        payload: {
            freshOutcomeData
        }
    }
};

const refreshPrematchOddsByOutcomeId = (freshOutcomeData) => {
    return {
        type : REFRESH_PREMATCH_ODDS_BY_OUTCOME_ID,
        payload: {
            freshOutcomeData
        }
    }
}

const changeActiveTab = (activeTab) => {
    return (dispatch) => {
        dispatch({type: CHANGE_ACTIVE_TAB, payload: {activeTab}});
        dispatch(calculateWinning());
        dispatch(checkFreetbetConditionValidity());
    }
};

const updateBetSlipLiveOutcomesWhenChangedError = (changedOutcome) => {
    return (dispatch) => {
        const {outcomeId, outcomeOddsFromUi, databaseOdds} = changedOutcome;
        dispatch({type: OUTCOME_CHANGED, payload: {outcomeId, outcomeOddsFromUi, databaseOdds}});
        dispatch(calculateWinning());
    }
};

const updateBetSlipLiveOutcomesWhenUnavailableError = (changedOutcome) => {
    return (dispatch) => {
        const {outcomeId} = changedOutcome;
        dispatch({type: OUTCOME_UNAVAILABLE, payload: {outcomeId}});
    }
};

const changeSlipType = (slipType) => {
    return (dispatch, getState) => {
        try {
            const {BetSlip:{activeTab, betSlips}} = getState();
            const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
            const disallowChange = (slipType == 'SYSTEM' && _size(outcomesOnTab) < 2);
            if (disallowChange) {
                return;
            }
            dispatch({type: CHANGE_SLIP_TYPE, payload: {slipType}});
            dispatch(updateBetSlipCombinationsTypes());
            dispatch(validateBetOutcomesCount());

        } catch (error) {
            dispatch(setBetSlipError(error));
        }
    };
};

const changeSlipStake = (stake) => {
    return (dispatch, getState) => {
        try {
            dispatch({type: CHANGE_SLIP_STAKE, payload: {stake}});
            dispatch(calculateWinning());
        } catch (error) {
            dispatch(setBetSlipError(error));
        }
    };
};

const changeSlipTotalStake = (totalStake) => {
    return (dispatch, getState) => {
        try {
            const {BetSlip:{activeTab, betSlips}} = getState();
            const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
            const blocksOnTab = _get(betSlips, [activeTab, 'blocks']);
            const hasAccumulator = _get(betSlips, [activeTab, 'addAccumulator']);
            const type = _get(betSlips, [activeTab, 'type', 'type']);
            const outcomesNotInBlocks = _reject(outcomesOnTab, {inBlock: true});
            const bankers = _filter(outcomesOnTab, {banker: true});
            const outcomesCount = _size(outcomesNotInBlocks) + _size(blocksOnTab) - _size(bankers);

            let combinationsCount = getSystemBetMultiplier(outcomesCount, type);
            if (type < 100 && hasAccumulator) {
                combinationsCount += 1;
            }

            let stake = decimal(totalStake / combinationsCount);

            const minStakePerCombination = process.env.MIN_STAKE_PER_COMBINATION && parseFloat(process.env.MIN_STAKE_PER_COMBINATION);
            if(minStakePerCombination && !isNaN(minStakePerCombination) && (parseFloat(stake) < minStakePerCombination)){
                stake = minStakePerCombination;
            }
            dispatch(changeSlipStake(stake));

            return stake;

        } catch (error) {
            dispatch(setBetSlipError(error));
        }
    };
};

const setBetSlipError = (error) => {
    return {
        type: SET_BETSLIP_ERROR,
        payload: {
            error
        }
    }
};

const clearBetSlipError = () => {
    return {
        type: CLEAR_BETSLIP_ERROR
    }
};

const setPossibleWinData = (possibleWinData) => {
    return {
        type: UPDATE_POSSIBLE_WIN_DATA,
        payload: {
            possibleWinData
        }
    }
};

const lockBetSlip = () => {
    return {
        type: LOCK_BETSLIP
    }
};

const unlockBetSlip = () => {
    return {
        type: UNLOCK_BETSLIP
    }
};

const placeBetSlipPending = () => {
    return {
        type: `${PLACE_BETSLIP}_PENDING`
    }
};

const placeBetSlipSuccess = (data = {}) => {
    return {
        type: `${PLACE_BETSLIP}_SUCCESS`,
        payload: {data}
    }
};

const placeBetSlipFailure = (error = {}) => {
    return {
        type: `${PLACE_BETSLIP}_FAILURE`,
        payload: {error}
    }
};

const placeBetSlipAgain = () => {
    return (dispatch, getState) => {
        const {BetSlip:{approvalData}} = getState();
        const approvalDataCopy = {...approvalData};
        dispatch({type: 'PLACE_BETSLIP_AGAIN'});
        dispatch(clearBetSlipError());
        if (approvalDataCopy) {
            dispatch(calculateWinning());
            dispatch(checkFreetbetConditionValidity());
        }
    };
};

const placeBetSlipForApprovalPending = () => {
    return {
        type: `${PLACE_BETSLIP_FOR_APPROVAL}_PENDING`
    }
};

const placeBetSlipForApprovalSuccess = (approvalData = {}) => {
    return {
        type: `${PLACE_BETSLIP_FOR_APPROVAL}_SUCCESS`,
        payload: {approvalData}
    }
};

const placeBetSlipForApprovalFailure = (error = {}) => {
    return {
        type: `${PLACE_BETSLIP_FOR_APPROVAL}_FAILURE`,
        payload: {error}
    }
};

const startIntervalFetchOddsByOutcomeId = (liveOutcomes) => {
    return (dispatch) => {
        const config = {
            key: 'FETCH_ODDS_BY_OUTCOMES_IDS',
            actionCreator: fetchOddsByOutcomesIds.bind(null, liveOutcomes),
            timeout: 10000
        };
        dispatch(startInterval(config));
    }
};

const stopIntervalFetchOddsByOutcomeId = () => {
    return (dispatch, getState) => {
        const key = 'FETCH_ODDS_BY_OUTCOMES_IDS';
        const {Intervals:{intervals}} = getState();
        const intervalExist = _find(intervals, {key});
        if (_size(intervalExist)) {
            dispatch(stopInterval(key));
        }
    }
};

const toggleAccumulator = () => {
    return (dispatch) => {
        dispatch({type: TOGGLE_ACCUMULATOR});
        dispatch(calculateWinning());
    }
};

const toggleBankerOutcome = (outcomeId) => {
    return (dispatch) => {
        dispatch({type: TOGGLE_BANKER_OUTCOME, payload: {outcomeId}});
        dispatch(updateBetSlipCombinationsTypes());
    }
};

const toggleOutcomeToBlock = (outcomeId) => {
    return {
        type: TOGGLE_OUTCOME_TO_BLOCK,
        payload: {
            outcomeId
        }
    }
};

const markOutcomesAsInBlock = (outcomesIdsMarkedAsInBlock) => {
    return {
        type: MARK_OUTCOMES_AS_IN_BLOCK,
        payload: {
            outcomesIdsMarkedAsInBlock
        }
    }
};

const unMarkOutcomesAsInBlock = (outcomesIdsUnMarkedAsInBlock) => {
    return (dispatch) => {
        dispatch({type: UNMARK_OUTCOMES_AS_IN_BLOCK, payload: {outcomesIdsUnMarkedAsInBlock}});
        dispatch(calculateWinning());
    }
};

const addOutcomesToBlock = (selectedOutcomes) => {
    return {
        type: ADD_OUTCOMES_TO_BLOCK,
        payload: {
            selectedOutcomes
        }
    }
};

const removeOutcomeFromBlock = (outcomeId) => {
    return (dispatch) => {
        dispatch(removeOutcome(outcomeId));
        // dispatch({type: REMOVE_OUTCOME_FROM_BLOCK, payload: {outcomeId}});
        // dispatch(updateBetSlipCombinationsTypes());
    }
};

const updateBlock = (firstOutcomeIdFromBlock, outcomeId) => {
    return (dispatch, getState) => {
        dispatch({type: UDPATE_EXISTING_BLOCK, payload: {firstOutcomeIdFromBlock, outcomeId}});
        dispatch(updateBetSlipCombinationsTypes());

        const {BetSlip: {activeTab, betSlips}} = getState();
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        const blocksOnTab = _get(betSlips, [activeTab, 'blocks']);
        const outcomesInBlock = _filter(outcomesOnTab, {inBlock: true});

        if ((_size(outcomesOnTab) == _size(outcomesInBlock)) && _size(blocksOnTab) == 1) {
            _each(outcomesInBlock, ({outcomeId}) => {
                dispatch(removeOutcome(outcomeId));
            });
            dispatch(changeSlipType('ACCUMULATOR'));
        } else {
            dispatch(validateBetOutcomesCount());
        }
    }
};

const createBlock = () => {
    return (dispatch, getState) => {
        try {
            const {BetSlip:{activeTab, betSlips}} = getState();
            const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
            const selectedOutcomes = _filter(outcomesOnTab, {selected: true});
            const outcomesIdsMarkedAsInBlock = _map(selectedOutcomes, 'outcomeId');

            if (_size(outcomesOnTab) == 2 && _size(selectedOutcomes) == 2) {
                _each(selectedOutcomes, ({outcomeId}) => {
                    dispatch(toggleOutcomeToBlock(outcomeId));
                });
                dispatch(changeSlipType('ACCUMULATOR'));
            } else {
                dispatch(markOutcomesAsInBlock(outcomesIdsMarkedAsInBlock));
                dispatch(addOutcomesToBlock(selectedOutcomes));
                dispatch(updateBetSlipCombinationsTypes());
            }

            dispatch(validateBetOutcomesCount());

        } catch (error) {
            dispatch(setBetSlipError(error));
        }
    }
};

const setTotalBonusData = (totalBonusData) => {
    return {
        type: UPDATE_TOTAL_BONUS_DATA,
        payload: {
            totalBonusData
        }
    }
};


const prepareBetSlipRequest = (BetSlipModel) => {

    const omittedProperties = [
        'title',
        'gameId',
        'combinationType',
        'combinationTypes',
        'categoryId',
        'class',
        'style',
        'dataOriginalTitle',
        'ariaDescribedby',
        'outcomeName',
        'gameName',
        'eventName',
        'inBlock',
        'order',
        'selected'
    ];
    const omitProperties = (outcome)=>{
        return _omit(outcome, omittedProperties);
    };
    const {activeTab, acceptAnyOdds, acceptHigherOdds, bonusBet, balanceId, betSlips} = {...BetSlipModel};
    let {blocks, addAccumulator, outcomes, stake, type, freebet, selectedPromotion} = _get(betSlips, [activeTab]);
    const countryCode = process.env.MAIN_COUNTRY_CODE;
    const currencyCode = process.env.MAIN_CURRENCY_CODE;

    let outcomesArr = [];
    let betType = _get(type, 'type');
    if (betType == 0) {
        betType = _size(outcomes) == 1 ? 100 : 0;
        outcomesArr = _map(outcomes, (o) => {
            return {...o, inBlock: false, banker: false}
        });
        addAccumulator = false;
    } else {
        const outcomesNotInBlock = _reject(outcomes, {inBlock: true});
        outcomesArr = outcomesNotInBlock;
    }

    blocks = _map(blocks, ({blockOrder, outcomes}) => ({blockOrder, outcomes:_map(outcomes, omitProperties)}));
    outcomesArr = _map(outcomesArr, omitProperties);

    const config = {
        blocks,
        stake,
        bonusBet,
        acceptAnyOdds,
        acceptHigherOdds,
        addAccumulator,
        countryCode,
        currencyCode,
        betType,
        countdowned: false,
        betSlipHash: '',
        outcomes: outcomesArr
    };

    if (bonusBet) {
        _set(config, 'wagerBonusId', balanceId);
    }

    if (_size(freebet) && [0, 100].indexOf(betType) != -1) {
        _set(config, 'freebetId', _get(freebet, 'offerFreebetId'));
        _set(config, 'freebetCode', _get(freebet, 'offerCode'))
    }

    if (_size(blocks)) {
        config['blocks'] = betType != 0 ? _map(blocks, 'outcomes') : [];
    }

    if(selectedPromotion){
      _set(config, 'offerId', selectedPromotion.offerId)
      _set(config, 'offerDefinitionId', selectedPromotion.offerDefinitionId)
    }

    return config;
};

const calculatePossibleWin = (taxFactor) => {
    return async(dispatch, getState, {BettingApi}) => {

        try {
            const {BetSlip}= getState();
            const config = prepareBetSlipRequest(BetSlip);
            const {outcomes} = config;
            if (taxFactor) {
                taxFactor = Number(taxFactor);
                const taxOutcomeModel = {
                    banker: false,
                    eventId: -1,
                    eventType: -1,
                    outcomeId: -1,
                    sportId: -1002,
                    outcomeLive: false,
                    outcomeOdds: taxFactor
                };
                outcomes.push(taxOutcomeModel);
            }

            const {code, data} = await BettingApi.calculatePossibleWin(config);
            if (code == 200) {
                data['taxFactor'] = taxFactor;
                dispatch(setPossibleWinData(data));
                dispatch(checkFreetbetConditionValidity());
                return data;
            }

            const betSlipErrorCode = _get(data, ['0', 'betSlipErrorCode']);
            throw {message: translation(`betslip_error${betSlipErrorCode}`)};

        } catch ({message}) {
            dispatch(setBetSlipError(message));
        }
    }
};

const calculateTaxFactor = () => {
    return async(dispatch, getState, {BettingApi}) => {
        try {
            const {BetSlip}= getState();
            const config = prepareBetSlipRequest(BetSlip);
            const {code, data} = await BettingApi.calculateTaxFactor(config);
            if (code == 200) {
                return data;
            }

            throw {message: translation(`error_${code}`)};
        } catch ({message}) {
            dispatch(setBetSlipError(message));
        }
    }
};

const calculateBetSlipData = () => {
    return async(dispatch, getState, {BettingApi}) => {

        try {
            const {BetSlip}= getState();
            const config = prepareBetSlipRequest(BetSlip);

            const {code, data} = await BettingApi.calculateBetSlipData(config);
            if (code == 200) {
                dispatch(setPossibleWinData(data));
                dispatch(setTotalBonusData(data.totalBonus));
                return data;
            }

            const betSlipErrorCode = _get(data, ['0', 'betSlipErrorCode']);
            throw {message: translation(`betslip_error${betSlipErrorCode}`)};

        } catch ({message}) {
            dispatch(setBetSlipError(message));
        }
    }
};

const calculateWinningCallback = (dispatch, getState) => {
    try {

        const {BetSlip:{activeTab, betSlips}} = getState();
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        if (!_size(outcomesOnTab)) {
            return;
        }

        const actionsPromises = [];
        const calculateBetSlipDataFromApi = process.env.CALCULATE_BETSLIP_DATA_FROM_API && JSON.parse(process.env.CALCULATE_BETSLIP_DATA_FROM_API);
        if(calculateBetSlipDataFromApi){
          actionsPromises.push(calculateBetSlipData);
        } else{
          
          actionsPromises.push(calculatePossibleWin);
  
          const hasTaxFactor = process.env.TAX_FACTOR && JSON.parse(process.env.TAX_FACTOR);
          if (hasTaxFactor) {
              actionsPromises.unshift(calculateTaxFactor);
          }
        }
  
        const hasPropositionOffers = process.env.PROPOSITION_OFFERS;
        if (hasPropositionOffers) {
            actionsPromises.push(calculatePropositionOffers);
        }

        actionsPromises.reduce((prevAction, nextAction) => {
            return prevAction.then((response) => {
                return dispatch(nextAction(response));
            });
        }, Promise.resolve());

    } catch (error) {
        dispatch(setBetSlipError(error));
    }

};

const debouncedCalculateWinning = _debounce(calculateWinningCallback, 100);

const calculateWinning = () => {
    return debouncedCalculateWinning;
};

const constructOutcome = (target) => {
    let outcome = getAttributesFromOutcome(target);
    if (outcome.outcomeId != -1) {
        const {outcomeLive} = outcome;
        if (outcomeLive == 'true') {
            // from cache
        } else {
            // from cache
        }

        const plainOutcomeData = app.service.Events.getOutcomeFromCache(outcome.eventId, outcome.gameId, outcome.outcomeId);
        if (plainOutcomeData) {
            outcome.outcomeName = plainOutcomeData.outcomeName;
            outcome.gameName = plainOutcomeData.gameName;
            outcome.gameType = plainOutcomeData.gameType;
            outcome.eventName = plainOutcomeData.eventName;
            outcome.outcomeOdds = plainOutcomeData.outcomeOdds;
            outcome.outcomeOddsWithoutFof = plainOutcomeData.outcomeOddsWithoutFof ? plainOutcomeData.outcomeOddsWithoutFof : null;
        }

        if (outcome.eventType === 23) { // Playtech Virtuals
            // $this.betSlip.eventType = outcome.eventType
        } else {
            //  delete $this.betSlip.eventType;
        }
    }

    outcome.inBlock = false;
    outcome.banker = false;
    return outcome;
};

const constructVirtualOutcome = (target) => {
    let outcome = target;
    outcome.banker = false; // banker parameter is required to place bet
    return outcome;
};

const getAttributesFromOutcome = (target) => {
    const attributes = {};
    _each(target.attributes, ({name, value}) => {
        name = _camelCase(name);
        const isNumber = !isNaN(value); // !isNaN(parseFloat(value));
        attributes[name] = isNumber ? Number(value) : value;
    });
    return attributes;
};

const validateBetOutcomesCount = (lessOnlyCondition = false) => {
    return (dispatch, getState) => {
        const maxOutcomesOnSlip = process.env.MAX_OUTCOMES_ON_SLIP && JSON.parse(process.env.MAX_OUTCOMES_ON_SLIP);
        const {BetSlip: {activeTab, betSlips}} = getState();
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        const slipType = _get(betSlips, [activeTab, 'slipType']);
        const blocks = _get(betSlips, [activeTab, 'blocks']);
        const outcomesNotInBlock = _reject(outcomesOnTab, {inBlock: true});
        let betslipTotalOutcomesAmount  = process.env.COUNT_BETSLIP_TOTAL_OUTCOMES_AMOUNT;
        betslipTotalOutcomesAmount = betslipTotalOutcomesAmount && JSON.parse(betslipTotalOutcomesAmount);
        const allOutcomes = (slipType == 'ACCUMULATOR' || betslipTotalOutcomesAmount) ? outcomesOnTab.length: (outcomesNotInBlock.length + blocks.length);
        const isValid = lessOnlyCondition ? (allOutcomes < maxOutcomesOnSlip) : (allOutcomes <= maxOutcomesOnSlip);

        if (isValid) {
            dispatch(clearBetSlipError());
        }

        return isValid;
    }
};

const addOutcome = (target, externalAdding = false) => {
  return (dispatch, getState) => {

        const outcome = externalAdding ? (target?.virtualSport ? constructVirtualOutcome(target) : target ) : constructOutcome(target);
        const { eventId, outcomeId, combinationTypes, combinationType, gameId} = outcome;
        const {BetSlip:{activeTab, betSlips}} = getState();
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);

        if(app.config.customBetAvailable){
          if(app.service.CustomBet.customBetEnabled){
            if(eventId && app.service.CustomBet.customBet.eventId){
              app.service.CustomBet.selectCustomBetOutcome(target);
              return;
            }
          } else{
            if(app.config.customBetMTSDisabled) {
              if(_find(outcomesOnTab, (outcome) => outcome.isCustomBet && outcome.eventId == eventId)) {
                app.service.CustomBet.renderCustomBetMessage("customBetExistWithEventId");
                return;
              }
            } else {
              if(_find(outcomesOnTab, (outcome) => outcome.isCustomBet)) {
                app.service.CustomBet.renderCustomBetMessage("outcomeCannotBeCombinedWithCustomBet");
                return;
              }
            }
          }
        }
        const betSlipSystemLettersCount = process.env.BETSLIP_SYSTEM_MAX_BLOCK_COUNT && JSON.parse(process.env.BETSLIP_SYSTEM_MAX_BLOCK_COUNT);

        const outcomesFromSameEvent = _filter(outcomesOnTab, {eventId});
        if (_size(outcomesFromSameEvent)) {

            const [firstExistingOutcome] = outcomesFromSameEvent;
            const {outcomeId:existingOutcomeId} = firstExistingOutcome;

            if (!_some(outcomesFromSameEvent, ['outcomeId', outcomeId])) {

                const multipleCombinationTypes = process.env.MULTIPLE_COMBINATION_TYPES && JSON.parse(process.env.MULTIPLE_COMBINATION_TYPES);
                if (multipleCombinationTypes && (combinationTypes != 'undefined' || combinationType)) {

                    let currentCombination = combinationTypes != 'undefined' ? combinationTypes : combinationType;
                    currentCombination = _map(String(currentCombination).split(','), Number);

                    const allowCombine = _reduce(outcomesFromSameEvent, (initial, {combinationTypes, combinationType}) => {
                        if (!initial) {
                            return initial;
                        }
                        let nextCombination = combinationTypes != 'undefined' ? combinationTypes : combinationType;
                        nextCombination = _map(String(nextCombination).split(','), Number);

                        const intersectionArray = _intersection(currentCombination, nextCombination);
                        return initial && !_size(intersectionArray);
                    }, true);
                    if (allowCombine) {
                        dispatch({type: ADD_OUTCOME, payload: {outcome}});
                    } else {
                        const outcomeFromSameGame = _filter(outcomesFromSameEvent, {gameId} );
                        if (_size(outcomeFromSameGame) > 0) {
                            _forEach(outcomeFromSameGame, o => {
                                // first to remove from block second to remove from slip
                                if (o.inBlock) {
                                    dispatch(removeOutcome(o.outcomeId));
                                }
                                dispatch(removeOutcome(o.outcomeId));
                            });
                            dispatch({ type: ADD_OUTCOME, payload: { outcome } });
                        } else if (_size(outcomesFromSameEvent) > 1) {
                            const error = translation(`betSlip_sameCombinationTypeError`);
                            dispatch(setBetSlipError(error));
                        } else {
                            // first to remove from block second to remove from slip
                            if (firstExistingOutcome.inBlock) {
                                dispatch(removeOutcome(existingOutcomeId));
                            }
                            dispatch(removeOutcome(existingOutcomeId));
                            dispatch({type: ADD_OUTCOME, payload: {outcome}});
                        }
                    }
                } else {
                    // first to remove from block second to remove from slip
                    if (firstExistingOutcome.inBlock) {
                        dispatch(removeOutcome(existingOutcomeId));
                    }
                    dispatch(removeOutcome(existingOutcomeId));
                    dispatch({type: ADD_OUTCOME, payload: {outcome}});
                }

            } else {
                dispatch(removeOutcome(outcomeId));
            }

            dispatch(updateBetSlipCombinationsTypes());

        } else {

            const isValid = dispatch(validateBetOutcomesCount(true));
            if (isValid) {
                dispatch({type: ADD_OUTCOME, payload: {outcome}});
                if (betSlipSystemLettersCount) {
                    const blocks = _get(betSlips, [activeTab, 'blocks']);
                    const outcomesNotInBlock = _reject(outcomesOnTab, {inBlock: true});
                    const outcomesNotBanker = _reject(outcomesNotInBlock, {banker: true});
                    const groupCount = _size(blocks) + _size(outcomesNotBanker);
                    if (groupCount >= betSlipSystemLettersCount) {
                        dispatch(toggleBankerOutcome(outcomeId));
                    }
                }
                dispatch(updateBetSlipCombinationsTypes());
            }else{
                const maxOutcomesReachedErrorMsg = translation(`betSlip_slipMessage_maxOutcomesReached`);
                dispatch(setBetSlipError(maxOutcomesReachedErrorMsg));
            }

        }

    };
};

const removeOutcome = (outcomeId) => {
    return (dispatch, getState) => {
        const {BetSlip:{activeTab, betSlips}} = getState();
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        const outcomesSizeOnTab = _size(outcomesOnTab);
        if (outcomesSizeOnTab == 2) {
            dispatch(changeSlipType('ACCUMULATOR'))
        }

        dispatch({type: REMOVE_OUTCOME, payload: {outcomeId}});
        dispatch(updateBetSlipCombinationsTypes());
        dispatch(validateBetOutcomesCount());
    };
};

const changeCombinationType = (type) => {
    return (dispatch) => {
        dispatch({type: CHANGE_COMBINATION_TYPE, payload: {type}});
        dispatch(calculateWinning());
        dispatch(checkFreetbetConditionValidity());
    }
};

const updateBetSlipCombinationsTypes = () => {
    return debouncedUpdateBetSlipCombinationsTypes;
};

const updateBetSlipCombinationsTypesCallback = (dispatch, getState) => {
    const {BetSlip:{activeTab, betSlips}} = getState();
    const {outcomes, slipType, blocks, addAccumulator} = _get(betSlips, [activeTab]);
    const types = getBetslipTypes(outcomes, blocks, slipType, addAccumulator);
    const sortedTypes = _sortBy(types, ['type']);
    const firstTypeId = _get(sortedTypes, ['0', 'type']);

    dispatch({type: UPDATE_COMBINATION_TYPES, payload: {types: sortedTypes}});
    dispatch(changeCombinationType(firstTypeId));
};

const debouncedUpdateBetSlipCombinationsTypes = _debounce(updateBetSlipCombinationsTypesCallback, 500);

const fetchOddsByOutcomesIds = (outcomes) => {
    return (dispatch, getState, {LiveApi, VirtualApi}) => {

        _each(outcomes, async({outcomeId}) => {
            try {
                const {BetSlip:{activeTab, betSlips}, Auth:{isLogged}} = getState();
                const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
                const outcome = _find(outcomesOnTab, {outcomeId});

                const refreshOddsByOutcomeIdAction = (outcome?.virtualSport == 'VTI') ? _get(VirtualApi, ['refreshOddsByOutcomeId']) :  _get(LiveApi, ['refreshOddsByOutcomeId'])
                const {code, data:[freshOutcomeData]} = await refreshOddsByOutcomeIdAction(outcomeId);

                /*
                * It's necessary to consider FOF factor for odds on bet slip after they are refreshed
                * FOF factor can be calculated for specific eventId, categoryLvl3, categoryLvl2, categoryLvl1, eventType or gameType so all these parameters should be provided
                */
                if(app.config.fofEnabled?.fofPerCustomerEnabled && isLogged){
                    const dataForFOF = {eventId: outcome.eventId,
                        categoryLvl3: outcome.sportId,
                        categoryLvl2: outcome.countryId,
                        categoryLvl1: outcome.categoryId,
                        eventType: outcome.eventType,
                        gameType: outcome.gameType,
                        outcomes: [{outcomeOdds: freshOutcomeData.outcomeOdds}]
                    }
                    freshOutcomeData['outcomeOdds'] = app.service.FlexibleOdds.convertSingleOddsForFof(dataForFOF);
                }

                const prevOdds = freshOutcomeData['prevOdds'] = outcome['outcomeOdds'];
                const freshOdds = Number(_get(freshOutcomeData, ['outcomeOdds'], prevOdds)).toFixed(2);
                if (!('outcomeOdds' in freshOutcomeData)) {
                    freshOutcomeData['outcomeOdds'] = freshOdds;
                }

                if (code == 200) {
                    dispatch(refreshOddsByOutcomeId(freshOutcomeData));
                    if (freshOdds != prevOdds) {
                        dispatch(calculateWinning());
                    }
                }
            } catch (error) {
                dispatch(setBetSlipError(error));
            }
        });
    }
};

const placeBet = (betInShop = false) => {
    return async(dispatch, getState, {BettingApi}) => {

        dispatch(placeBetSlipPending());

        try {
            const {BetSlip}= getState();
            const config = prepareBetSlipRequest(BetSlip);
            const requestAction = betInShop ? BettingApi['betInShop'] : BettingApi['placeBet'];
            const {code, data} =  await requestAction(config);
            if (code == 200) {

                const fetchPlacedBetDetails = process.env.PLACED_BET_DETAILS_ON_PLACE_BET_SUCCESS;
                if ( fetchPlacedBetDetails ) {
                    const { slipId } = data;
                    dispatch(getPlacedBetDetails(slipId));
                }

                if (betInShop) {
                    dispatch(placeBetSlipSuccess(data));
                    dispatch(calculateWinning());
                } else {                    
                    batch(() => {
                        dispatch(placeBetSlipSuccess(data));
                        if ('wagerBonusId' in config) {
                            const wagerBonusId = _get(config, 'wagerBonusId', 0);
                            const amount = _get(data, 'newBonusBalance');
                            const bonusBalanceList = [].concat({wagerBonusId, amount});
                            _set(data, 'bonusBalanceList', bonusBalanceList);
                        }
                        dispatch(updateUserBalance(data));
                        dispatch(calculateWinning());
                    });
                }
                return data;
            }

            throw {message: data};

        } catch ({message}) {
            dispatch(handleBetSlipErrors(message));
        }
    }
};

const placeBetSlipForApproval = () => {
    return async(dispatch, getState, {BettingApi}) => {

        dispatch(placeBetSlipForApprovalPending());

        try {
            const {BetSlip}= getState();
            const config = prepareBetSlipRequest(BetSlip);
            const {code, data} =  await BettingApi.placeBetForApproval(config);

            if (code == 200) {
                dispatch(placeBetSlipForApprovalSuccess(data));
                dispatch(startIntervalFetchApprovedSlips());
                return data;
            }

            throw {message: data};

        } catch ({message}) {
            dispatch(placeBetSlipForApprovalFailure(message));
            dispatch(handleBetSlipErrors(message));
        }
    }
};

const handleBetSlipErrors = (errors) => {
    return (dispatch, getState) => {

        dispatch(placeBetSlipFailure());
        
        const resultErrorMsg = [];
        _each(errors, ({betSlipErrorCode, errorDetails, referenceObject}) => {
            let errorMsg = '';
            let errorKey = '';
            let approval = false;
            const {BetSlip:{activeTab, betSlips}} = getState();
            const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
            const quickBet = _get(betSlips, [activeTab, 'quickBet']);

            switch (betSlipErrorCode) {
                case 1:
                    const {BetSlip:{balanceId}} = getState();
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    if (!balanceId) {
                        // show deposit btn and hide login btn
                    } else {
                        errorKey = `betlip_error_bonus_balance_too_low`;
                    }
                    errorMsg = translation(errorKey);
                    break;
                case 5:
                    const outcome = _find(outcomesOnTab, {gameId: referenceObject});
                    let gameName = _get(outcome, 'gameName');
                    gameName = gameName ? [].concat(gameName) : null;

                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, gameName);
                    break;
                case 7:
                case 8:
                    if (!quickBet) {
                        dispatch(updateBetSlipLiveOutcomesWhenUnavailableError(errorDetails));
                    }
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(errorDetails.outcomeName));
                    break;
                case 9:
                    dispatch(updateBetSlipLiveOutcomesWhenUnavailableError(errorDetails));
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(errorDetails.outcomeName));
                    break;
                case 11:
                case 12:
                    const {colBD} = errorDetails;
                    const newColBD = Math.floor(parseFloat(colBD) * 100.0) / 100;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(newColBD));
                    break;
                case 13:
                    const {stakeLimit} = errorDetails;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(stakeLimit));
                    break;
                case 10:
                case 15:
                    referenceObject = parseFloat(referenceObject);
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(referenceObject));
                    break;
                case 18:
                    if (!quickBet) {
                        batch(() => {
                            dispatch(updateBetSlipLiveOutcomesWhenChangedError(errorDetails));
                        });
                    }

                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(errorDetails.outcomeName));

                    dispatch(unlockBetSlip());

                    break;
                case 21:
                    const {currencyMinimalStake} = errorDetails;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(currencyMinimalStake));
                    break;
                case 24:
                    const excludedValidToDate = formatDate(referenceObject, 'yyyy-MM-dd HH:mm');
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(excludedValidToDate));
                    break;
                case 30:
                case 31:
                    const hasLiveOutcomes = _some(outcomesOnTab, {outcomeLive: 'true'});
                    let disabledLiveApprovals = process.env.DISABLED_LIVE_APPROVALS;
                    disabledLiveApprovals = disabledLiveApprovals && JSON.parse(disabledLiveApprovals);
                    approval = (hasLiveOutcomes && disabledLiveApprovals) ? false : true;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey);
                    break;
                case 32:
                    const {minNumberOfOutcomes} = errorDetails;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(minNumberOfOutcomes));
                    break;
                case 46:
                    let {remoteErrorCode} = errorDetails;
                    errorKey = `betslip_error46_${remoteErrorCode}`;
                    errorKey = remoteErrorCode != undefined && remoteErrorCode != translation(errorKey) ? errorKey : 'betslip_error46';
                    errorMsg = translation(errorKey);

                    break;
                case 110:
                    const { minOdds } = errorDetails;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(minOdds));
                    break;
                case 111:
                    const { maxOdds } = errorDetails;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(maxOdds));
                    break;
                case 112:
                    const { minOutcomes } = errorDetails;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(minOutcomes));
                    break;
                case 113:
                    const { maxOutcomes } = errorDetails;
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey, [].concat(maxOutcomes));
                    break;
                default:
                    errorKey = `betslip_error${betSlipErrorCode}`;
                    errorMsg = translation(errorKey);
            }

            const autoApproval = process.env.AUTO_APPROVAL;
            const manualApproval = process.env.MANUAL_APPROVAL;
            if (approval) {
                if (autoApproval) {
                    dispatch(placeBetSlipForApproval());
                } else if (manualApproval) {
                    // show buttons
                    // ok => placeBetSlipForApproval(), cancel(clearSlip?)
                }
            }

            resultErrorMsg.push(errorMsg);
        });

        dispatch(setBetSlipError(resultErrorMsg));
    }
};


const placeBetInShop = () => {
    return async(dispatch) => {
        try {
            const params = {placeBet: true};
            await dispatch(placeBet(params));
        } catch (error) {
            dispatch(setBetSlipError(error));
        }
    }
};

const selectOutcomes = (outcomes) => {
    if (!_size(outcomes)) {
        return;
    }
    const outcomesSelectors = _map(outcomes, ({outcomeId}) => `button[outcome-id="${outcomeId}"]`).join(',');
    const outcomesButtons = [...document.querySelectorAll(outcomesSelectors)];
    _map(outcomesButtons, (dom) => dom.classList.add('active'));
};

const deselectOutcomes = (outcomes) => {
    if (!_size(outcomes)) {
        return;
    }
    const outcomesSelectors = _map(outcomes, ({outcomeId}) => `button[outcome-id="${outcomeId}"]`).join(',');
    const outcomesButtons = [...document.querySelectorAll(outcomesSelectors)];
    _map(outcomesButtons, (dom) => dom.classList.remove('active'));
};

const toggleOutcomes = (outcomes) => {
    if (!_size(outcomes)) {
        return;
    }
    const outcomesSelectors = _map(outcomes, ({outcomeId}) => `button[outcome-id="${outcomeId}"]`).join(',');
    const outcomesButtons = [...document.querySelectorAll(outcomesSelectors)];
    _map(outcomesButtons, (dom) => dom.classList.toggle('active'));
};

const calculateTotalBonus = () => {
    return async (dispatch, getState, { BettingApi }) => {

        try {
            const { BetSlip } = getState();
            const config = prepareBetSlipRequest(BetSlip);

            const { code, data } = await BettingApi.getTotalBonusData(config);
            if (code == 200) {
                dispatch(setTotalBonusData(data));
                return data;
            }

        } catch ({ message }) {
            // dispatch(setBetSlipError(message));
        }
    }
};

const calculatePropositionOffers = () => {
    return async (dispatch, getState, { BettingApi }) => {

        try {
            const { BetSlip } = getState();
            const config = prepareBetSlipRequest(BetSlip);
            const { code, data } = await BettingApi.calculatePropositionOffers(config);
            if (code == 200) {
                dispatch(setPropositionOffersData(data));
                return data;
            }

        } catch ({ message }) {
        }
    }
};

const setPropositionOffersData = (propositionOffers) => {
    return {
        type: UPDATE_PROPOSITION_OFFERS,
        payload: {
            propositionOffers
        }
    }
};

const getPlacedBetDetails = (slipId) => {

    return async (dispatch, getState, { TransactionApi }) => {

        try {

            const { BetSlip: {activeTab} } = getState();
            const {code, data } = await TransactionApi.getBetSlipTransaction(slipId);

            if (code == 200) {
                dispatch(setPlacedBetDetails(activeTab, data));
                return data;
            }
        } catch ({ message }) {

        }
    }

};

const setPlacedBetDetails = (activeTab, placedBetDetails) => {

    return {
        type: UPDATE_PLACED_BET_DETAILS,
        payload: {
            activeTab,
            placedBetDetails
        }
    }
}

export {
    changeCombinationType,
    toggleBalance,
    changeActiveTab,
    changeSlipType,
    fetchOddsByOutcomesIds,
    removeOutcome,
    addOutcome,
    changeSlipStake,
    changeSlipTotalStake,
    clearBetslip,
    placeBet,
    placeBetInShop,
    placeBetSlipAgain,
    startIntervalFetchOddsByOutcomeId,
    stopIntervalFetchOddsByOutcomeId,
    toggleOutcomeToBlock,
    createBlock,
    toggleBankerOutcome,
    toggleAccumulator,
    calculateWinning,
    toggleAcceptAnyOdds,
    toggleAcceptHigherOdds,
    placeBetSlipSuccess,
    refreshOddsByOutcomeId,
    refreshPrematchOddsByOutcomeId,
    removeOutcomeFromBlock,
    unMarkOutcomesAsInBlock,
    updateBlock,
    selectOutcomes,
    deselectOutcomes,
    toggleAcceptDefaultOdds,
    setPredefiniedStake,
    calculateTotalBonus,
    setTotalBonusData,
    setPropositionOffersData,
    unlockBetSlip,
    getPlacedBetDetails,
    setPlacedBetDetails,
    updateBetSlipCombinationsTypes
}