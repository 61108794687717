import _size from 'lodash/size';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import {translation} from 'utilsHelper.js';
import {startInterval, stopInterval} from 'intervalActions.js';
import {updateUserBalance} from 'authActions.js';
import { setSegmentationCookie, setSuccessedDepositsCounterCookie, getSuccessedDepositsCounterCookie } from 'helpers/cookiesHelper.js';
import { processCustomerDepositData } from 'helpers/customerDataHelper.js';

export const LOAD_PERSONAL_DATA = 'LOAD_PERSONAL_DATA';
export const LOAD_CUSTOMER_LIMITS = 'LOAD_CUSTOMER_LIMITS';
export const LOAD_CUSTOMER_LIMITS_OPTIONS = 'LOAD_CUSTOMER_LIMITS_OPTIONS';
export const SET_CUSTOMER_REGULATIONS = 'SET_CUSTOMER_REGULATIONS';
export const UPDATE_CUSTOMER_LIMITS = 'UPDATE_CUSTOMER_LIMITS';
export const LOAD_GIIF_DATA = 'LOAD_GIIF_DATA';
export const LOAD_DOCUMENT_TYPES = 'LOAD_DOCUMENT_TYPES';
export const LOAD_DOCUMENT_SCANS = 'LOAD_DOCUMENT_SCANS';
export const LOAD_CUSTOMER_CONSENTS = 'LOAD_CUSTOMER_CONSENTS'; 
export const UPDATE_CUSTOMER_CONSENT = 'UPDATE_CUSTOMER_CONSENT';
export const GET_CUSTOMER_BANK_LIST = 'GET_CUSTOMER_BANK_LIST';
export const VALIDATE_BANK_ACCOUNT = 'VALIDATE_BANK_ACCOUNT';
export const VALIDATE_BVN_NUMBER = 'VALIDATE_BVN_NUMBER';
export const LOAD_CUSTOMER_DEFINITION_CONSENT = 'LOAD_CUSTOMER_DEFINITION_CONSENT';
export const LOAD_CUSTOMER_CONTAINERS_CONSENTS = 'LOAD_CUSTOMER_CONTAINERS_CONSENTS';
export const UPLOAD_CUSTOMER_AVATAR = 'UPLOAD_CUSTOMER_AVATAR';
export const GET_CUSTOMER_AVATAR = 'GET_CUSTOMER_AVATAR';
export const GET_CUSTOMER_PROMOTIONS = 'GET_CUSTOMER_PROMOTIONS';
export const UPDATE_CUSTOMER_PROMOTIONS = 'UPDATE_CUSTOMER_PROMOTIONS';
export const LOAD_PUBLIC_REG_CONSENTS = 'LOAD_PUBLIC_REG_CONSENTS';
export const GET_CUSTOMER_DAYS_FROM_REGISTRATION= 'GET_CUSTOMER_DAYS_FROM_REGISTRATION'
export const UPDATE_MYID_STATUS = 'UPDATE_MYID_STATUS'

const startLoadCustomerDataInterval = () => {
    return (dispatch) => {
        const config = {
            key: 'LOAD_CUSTOMER_DATA',
            actionCreator: loadCustomerData,
            timeout: 20000
        };
        dispatch(startInterval(config));
    }
};

const stopLoadCustomerDataInterval = () => {
    return (dispatch, getState) => {
        const key = 'LOAD_CUSTOMER_DATA';
        const {Intervals:{intervals}} = getState();
        const intervalExist = _find(intervals, {key});
        if (_size(intervalExist)) {
            dispatch(stopInterval(key));
        }
    }
};

const kycStatusCheck = ()=>{
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} = await CustomerApi.getCustomerKycStatus();
            if(code != 200){
                throw {message: translation(`api_error_${code}`)};
            }
            return data;
        }catch(error){
            throw error;
        }
    }
};

const loadCustomerData = (requestProperties)=>{
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} = await CustomerApi.getCustomerData(requestProperties);
            if(code != 200){
                throw {message:`customer data error`};
            }
            const config = {
                newBalance: data.balance,
                bonusBalanceList: data.bonusBalanceList,
                creditBonusBalance: data.creditBonusBalance
            };

            if(process.env.DEPOSIT_COUNTER_COOKIE_ENABLED && JSON.parse(process.env.DEPOSIT_COUNTER_COOKIE_ENABLED)) {
              processCustomerDepositData(data);
            }
            dispatch(updateUserBalance(config));

        } catch ({message}) {
            console.log(message);
        }
    }
};

const loadPersonalData = () => {
    return async(dispatch, getState, {CustomerApi}) => {

        dispatch(loadPersonalDataPending());

        try {
            const {code, data} = await CustomerApi.getPersonalData();
            dispatch(loadPersonalDataSuccess(data));

            return data;
        } catch (error) {
            dispatch(loadPersonalDataFailure(error));
            throw error;
        }
    }
};

const cashierRegisterCustomer = (user) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data, description} = await CustomerApi.cashierRegisterCustomer(user);

            if (code == 500 || code == 418) {
                throw {message: translation(`login_api_error_${code}`)};
            } else if (code != 200) {
                throw {message: translation(`login_api_error_${description}`)};
            } else {
                return data;
            }
        } catch ({message}) {
            throw message;
        }
    };
};

const customActivateAccount = (activationData) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data, description} = await CustomerApi.customActivateAccount(activationData);

            if (code != 200) {
                throw {message: translation(`login_api_error_${code}`)};
            } else {
                return data;
            }
        } catch ({message}) {
            throw message;
        }
    };
}

const updatePersonalData = (userData) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const response = await CustomerApi.updatePersonalData(userData);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

const loadGiifData = () => {
    return async (dispatch, getState, { CustomerApi }) => {
        dispatch(loadPersonalDataPending());

        try {
            const { code, data } = await CustomerApi.getGiifTypes();
            dispatch(getGiifTypesSuccess(data));

            return data;
        } catch (error) {
            dispatch(getGiifTypesFailure(error));
            throw error;
        }
    };
};
  
const loadDocumentTypes = () => {
    return async (dispatch, getState, { CustomerApi }) => {
        dispatch(loadPersonalDataPending());

        try {
            const { code, data } = await CustomerApi.getDocumentTypes();
            dispatch(getDocumentTypesSuccess(data));
            return data;
        } catch (error) {
            dispatch(getDocumentTypesFailure(error));
            throw error;
        }
    };
};

const loadDocumentScans = () => {
    return async (dispatch, getState, { CustomerApi }) => {

        dispatch(loadPersonalDataPending());

        try {
            const { code, data } = await CustomerApi.getDocumentScans();
            dispatch(loadDocumentScansSuccess(data));
            return data;
        } catch (error) {
            dispatch(loadDocumentScansFailure(error));
            throw error;
        }
    }
};

const uploadDocumentData = (formData) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const response = await CustomerApi.uploadDocumentScan(formData);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

const loadCustomerConsents = () => {
 
    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {data} = await CustomerApi.getCustomerConsents();
            dispatch(loadCustomerConsentsSuccess(data));
            return data;
        } catch (error) {
            dispatch(loadCustomerConsentsFailure(error));
            throw error;
        }
    }
 
};

const updateCustomerConsents = (data) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const response = await CustomerApi.updateCustomerConsents(data);
            return response;
        } catch(error) {
            throw error;
        }

    }

};

const changeCustomerConsent = (checkboxName, value) => {
    return {
        type: `${UPDATE_CUSTOMER_CONSENT}`,
        payload: {checkboxName, value}
    }
};

const loadCustomerConsentsSuccess = (customerConsents) => {
    return {
        type: `${LOAD_CUSTOMER_CONSENTS}_SUCCESS`,
        payload: {customerConsents}
    }
};

const loadCustomerConsentsFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_CONSENTS}_FAILURE`,
        payload: {error}
    }
};

const updateCustomerLimitsPending = () => {
    return {
        type: `${UPDATE_CUSTOMER_LIMITS}_PENDING`
    }
};

const updateCustomerLimitsFailure = (error) => {
    return {
        type: `${UPDATE_CUSTOMER_LIMITS}_FAILURE`,
        payload: {error}
    }
};

const updateCustomerLimitsSuccess = (customerLimits) => {
    return {
        type: `${UPDATE_CUSTOMER_LIMITS}_SUCCESS`,
        payload: {customerLimits}
    }
};

const loadCustomerLimitsPending = () => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS}_PENDING`
    }
};

const loadCustomerLimitsFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS}_FAILURE`,
        payload: {error}
    }
};

const loadCustomerLimitsSuccess = (customerLimits) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS}_SUCCESS`,
        payload: {customerLimits}
    }
};

const loadCustomerLimitsOptionsPending = () => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS_OPTIONS}_PENDING`
    }
};

const loadCustomerLimitsOptionsFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS_OPTIONS}_FAILURE`,
        payload: {error}
    }
};

const loadCustomerLimitsOptionsSuccess = (limitOptions) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS_OPTIONS}_SUCCESS`,
        payload: {limitOptions}
    }
};

const loadPersonalDataFailure = (error) => {
    return {
        type: `${LOAD_PERSONAL_DATA}_FAILURE`,
        payload: {error}
    }
};

const loadPersonalDataSuccess = (userData) => {
    return {
        type: `${LOAD_PERSONAL_DATA}_SUCCESS`,
        payload: {userData}
    }
};

const loadDocumentScansFailure = (error) => {
    return {
        type: `${LOAD_DOCUMENT_SCANS}_FAILURE`,
        payload: {error}
    }
};

const loadDocumentScansSuccess = (documentScans) => {
    return {
        type: `${LOAD_DOCUMENT_SCANS}_SUCCESS`,
        payload: {documentScans}
    }
};

const getDocumentTypesFailure = (error) => {
    return {
        type: `${LOAD_DOCUMENT_TYPES}_FAILURE`,
        payload: {error}
    }
};

const getDocumentTypesSuccess = (documentTypes) => {
    return {
        type: `${LOAD_DOCUMENT_TYPES}_SUCCESS`,
        payload: {documentTypes}
    }
};

const getGiifTypesFailure = (error) => {
    return {
        type: `${LOAD_GIIF_DATA}_FAILURE`,
        payload: {error}
    }
};

const getGiifTypesSuccess = (giifData) => {
    return {
        type: `${LOAD_GIIF_DATA}_SUCCESS`,
        payload: {giifData}
    }
};

const loadPersonalDataPending = () => {
    return {
        type: `${LOAD_PERSONAL_DATA}_PENDING`
    }
};

const setCustomerRegulations = (customerRegulations) => {
    return {
        type: SET_CUSTOMER_REGULATIONS,
        payload: {customerRegulations}
    }
};

const isValueUnique = (fieldName, fieldValue) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            let fName = (fieldName == 'loginName') ? 'login' : fieldName;
            let fValue = fieldValue;
            const {code, data, description} = await CustomerApi.isValueUnique(fName, fValue);
            if (!data) {
                throw `This ${fName} is already used`;
            }
            return data;
        } catch (error) {
            throw error;
        }
    }
};

const acceptRegulations = ()=>{
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} =  await CustomerApi.acceptRegulations();
            if (code == 200) {
                dispatch(getCustomerBettingStatus());
                return data;
            }
        } catch (error) {
            throw error;
        }
    }
};

const getCustomerBettingStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} =  await CustomerApi.getCustomerBettingStatus();
            if (code == 200 && _size(data)) {
                dispatch(setCustomerRegulations(data));
                app.service.Customer.customerRegulations = data;
                return data;
            }
        } catch (error) {
            throw error;
        }
    }
};

const getCustomerSegmentStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} =  await CustomerApi.getCustomerSegmentStatus();

            if (code == 200) {
                if (_size(data)) setSegmentationCookie(data.filter(i => i > 0).join(','));
                else setSegmentationCookie(''); // set empty cookie if data is empty
            }
        } catch (error) {
            throw error;
        }
    }
};

const loadCustomerLimits = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        dispatch(loadCustomerLimitsPending());

        try {
            const {code, data} = await CustomerApi.getCustomerLimits();
            dispatch(loadCustomerLimitsSuccess(data));

            return data;
        } catch (error) {
            dispatch(loadCustomerLimitsFailure(error));
            throw error;
        }
    }
};

const loadCustomerLimitsOptions = () => {
    return async(dispatch, getState, {OtherApi}) => {
        dispatch(loadCustomerLimitsOptionsPending());

        try {
            const {code, data} = await OtherApi.getStaticConf();
            if(data.limitOptions){
              dispatch(loadCustomerLimitsOptionsSuccess(data.limitOptions));
            }

            return data.limitOptions;
        } catch (error) {
            dispatch(loadCustomerLimitsOptionsFailure(error));
            throw error;
        }
    }
};

const updateCustomerLimits = (limits) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} = await CustomerApi.updateCustomerLimits(limits);
            const {Customer:{customerRegulations}} = getState();
            const {bettingAllowed, bettingDisabledReasons} = customerRegulations||{};
            if (code == 200 && _size(data)) {
                const fieldsWithError = _filter(data, 'exceptionCode');
                const errors =_reduce(fieldsWithError, (initialObject, {limitType, exceptionCode, exceptionExtraMsg})=>{
                    const errorMsg = translation(`limits_limitUpdateInfo_error_${exceptionCode}`, (exceptionExtraMsg?[exceptionExtraMsg]:null));
                    const fiieldName =`limitType${limitType}`;
                    return {...initialObject, [fiieldName]: errorMsg}
                }, {});

                if(!bettingAllowed && [].concat(bettingDisabledReasons).indexOf('REGULATIONS_NOT_ACCEPTED')!=-1){
                    await dispatch(acceptRegulations());
                }
                dispatch(updateCustomerLimitsSuccess(data));
                app.service.Customer.getCustomerBettingStatus();

                if(_size(errors)){
                    // throw errors;
                }

                return data;
            }else{
                throw {_error: translation(`limitUpdateInfo_error_${code}`)};
            }
        } catch (error) {
            throw error;
        }
    }
};



const customerActivateAccount = (params) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} = await CustomerApi.customerActivateAccount(params);

            if(code == 200 && !data){
                throw {message: translation('accountActivate_notSuccess')};
            }else if(code == 457){
                throw {message: translation('accountActivate_notSuccessLinkExpired')};
            }else if(code != 200){
                throw {message: translation(`error_${code}`)};
            }

            return data;
        } catch ({message}) {
            throw message;
        }
    }
};

const getCustomerBankList = () => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const bankList = await CustomerApi.getCustomerBankList();
            dispatch(updateCustomerLimitsSuccess(bankList));
            return bankList;
        } catch (error) {
            dispatch(updateCustomerLimitsFailure(error));
            throw error;
        }
    }
};

const getCustomerBankListSuccess = (bankList) => {
    return {
        type: `${GET_CUSTOMER_BANK_LIST}_SUCCESS`,
        payload: {bankList}
    }
};

const getCustomerBankListFailure = (error) => {
    return {
        type: `${GET_CUSTOMER_BANK_LIST}_FAILURE`,
        payload: {error}
    }
};

const validateBankAccount = (data) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const status = await CustomerApi.validateBankAccount(data);
            dispatch(validateBankAccountSuccess(status));
            return status;
        } catch (error) {
            dispatch(validateBankAccountFailure(error));
            throw error;
        }
    }
};

const validateBankAccountSuccess = (status) => {
    return {
        type: `${VALIDATE_BANK_ACCOUNT}_SUCCESS`,
        payload: {status}
    }
};

const validateBankAccountFailure = (error) => {
    return {
        type: `${VALIDATE_BANK_ACCOUNT}_FAILURE`,
        payload: {error}
    }
};

const validateBVNNumber = (data) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const status = await CustomerApi.validateBVNNumber(data);
            dispatch(validateBVNNumberSuccess(status));
            return status;
        } catch (error) {
            dispatch(validateBVNNumberFailure(error));
            throw error;
        }
    }
};

const validateBVNNumberSuccess = (status) => {
    return {
        type: `${VALIDATE_BVN_NUMBER}_SUCCESS`,
        payload: {status}
    }
};

const validateBVNNumberFailure = (error) => {
    return {
        type: `${VALIDATE_BVN_NUMBER}_FAILURE`,
        payload: {error}
    }
};

const createCustomerConsentDefinition = (data) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const response = await CustomerApi.createCustomerConsentDefinition(data);
            return response;
        } catch(error) {
            throw error;
        }

    }

};

const updateCustomerConsentDefinition = ({definitionId, accepted}) => {
    
    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const response = await CustomerApi.updateCustomerConsentDefinition({definitionId, accepted});
            return response;
        } catch(error) {
            throw error;
        }

    }

};

const updateAllCustomerConsentDefinition = (data) => {
    
    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const response = await CustomerApi.createCustomerConsentDefinition(data);
            return response;
        } catch(error) {
            throw error;
        }

    }

};

const loadCustomerConsentContainers = (containersNames) => {
 
    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {data} = await CustomerApi.getCustomerConsentContainers(containersNames);
            dispatch(loadCustomerConsentContainersSuccess(data));
            return data;
        } catch (error) {
            dispatch(loadCustomerConsentContainersFailure(error));
            throw error;
        }
    }
};

const loadCustomerConsentContainersSuccess = (containers) => {
    return {
        type: `${LOAD_CUSTOMER_CONTAINERS_CONSENTS}_SUCCESS`,
        payload: {containers}
    }
};

const loadCustomerConsentContainersFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_CONTAINERS_CONSENTS}_FAILURE`,
        payload: {error}
    }
};

const isBankAccountNumberUniqueForCustomer = (fieldName, fieldValue) => {

    fieldValue = fieldValue ? fieldValue.replace(/\s+/g,'') : fieldValue;

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {data} = await CustomerApi.isBankAccountNumberUniqueForCustomer(fieldValue);
            return data;
        } catch (error) {
            throw error;
        }
    }
};

const uploadCustomerAvatar = (avatar) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {code, data} = await CustomerApi.uploadCustomerAvatar(avatar);
            if (code == 200 && _size(data)) {
                dispatch(uploadCustomerAvatarSuccess(data));
                return data;
            }
        } catch (error) {
            throw error;
        }
    }

};

const uploadCustomerAvatarSuccess = (customerAvatarUrl) => {

    return {
        type: `${UPLOAD_CUSTOMER_AVATAR}_SUCCESS`,
        payload: {customerAvatarUrl}
    }

};
const updateCustomerPromotion = (definitionId,booleanValue)=>{
    return async (dispatch,getState,{CustomerApi}) =>{
        try{
            const VipMarketingObj = {
                "definitionId" : definitionId,
                "accepted":booleanValue ? true:false
            }
            const {code,data} = await CustomerApi.updateCustomerConstans(VipMarketingObj)
            if (code===200){
                dispatch(updateCustomerPromotionSuccess(data))
            }else{
                dispatch(updateCustomerPromotionFailure({})) 
            }
            return data
            
        } catch(err){
            dispatch(updateCustomerPromotionFailure(err))
            throw err
        }
    }
}
const updateCustomerPromotionSuccess = (data)=>{
    return{
        type:`${UPDATE_CUSTOMER_PROMOTIONS}_SUCCESS`,
        payload:{data}
    }
}
const updateCustomerPromotionFailure = (data)=>{
    return{
        type:`${UPDATE_CUSTOMER_PROMOTIONS}_FAILURE`,
        payload:{data}
    }
}
const getCustomerDaysFromRegistration = ()=>{
    return async (dispatch, getState, { CustomerApi }) => {
        try {
            const {data}= await CustomerApi.getVeryficationStatus()
            if(_size(data)){
                let daysFromRegistration = (new Date() - new Date(data.account.registeredOn)) / (1000 * 60 * 60 * 24);
                daysFromRegistration > 30 && data.account.accountStatus ==='TEMPORARY_ACCOUNT'  ? daysFromRegistration= true: daysFromRegistration=false;
                dispatch(getCustomerDaysFromRegistrationSuccess(daysFromRegistration))
            }
        } catch (error) {
            dispatch(getCustomerDaysFromRegistrationFailure(false))
          throw error;
        }
      };
}
const getCustomerDaysFromRegistrationSuccess=(data)=>{
    return{
        type:`${GET_CUSTOMER_DAYS_FROM_REGISTRATION}_SUCCESS`,
        payload:{data}
    }
}
const getCustomerDaysFromRegistrationFailure=(data)=>{
    return{
        type:`${GET_CUSTOMER_DAYS_FROM_REGISTRATION}_FAILURE`,
        payload:{data}
    }
}
 
// temporary solution
const getCustomerPromotions=(booleanValue,param=false)=>{
    // booleanValue checks if customer wants to join VipMarketing or leaveit false=leave true = join
    return async (dispatch, getState, { CustomerApi }) => {
        try { 
          const { code, data } = await CustomerApi.getCustomerPromotions();
          if(param){
            dispatch(getCustomerPromotionsSuccess(data?.PROMOTIONS[0]))
            return
          }
          if(code==200 && _size(data)){
            dispatch(getCustomerPromotionsSuccess(data?.PROMOTIONS))
            dispatch(updateCustomerPromotion(data?.PROMOTIONS[0]?.definitionId, booleanValue))
            return data;
          }
        } catch (error) {
            dispatch(getCustomerPromotionsFailure(error))
            dispatch(updateCustomerPromotionFailure(error))
          throw error;
        }
      };
}
const getCustomerPromotionsFailure=(data)=>{
    return{
        type:`${GET_CUSTOMER_PROMOTIONS}_FAILURE`,
        payload:{data}
    }
}
const getCustomerPromotionsSuccess = (data)=>{
    return{
        type:`${GET_CUSTOMER_PROMOTIONS}_SUCCESS`,
        payload:{data}
    }
}

// for new consents
const loadCustomerConsentsByTagAndNames = ({tag, names}) => {
    return async (dispatch, getState, { CustomerApi }) => {
        try {
            const { code, data } = await CustomerApi.getCustomerConsentTags({tag, names});
            if(code==200 && _size(data)){
                dispatch(loadCustomerConsentsSuccess(data))
                return data;
            }
        } catch (error) {
            dispatch(loadCustomerConsentsFailure(error))
            throw error;
        }
    };
}

const getCustomerAvatar = () => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {code, data} = await CustomerApi.getCustomerAvatar();
            if (code == 200 && _size(data)) {
                dispatch(getCustomerAvatarSucess(data));
                return data;
            } else if (code == 404) {
                dispatch(getCustomerAvatarSucess(null))
            }
        } catch (error) {
            throw error;
        }
    }
    
};

const getCustomerAvatarSucess = (customerAvatarUrl) => {

    return {
        type: `${GET_CUSTOMER_AVATAR}_SUCCESS`,
        payload: {customerAvatarUrl}
    }

};

const loadPublicRegConsents = (containersNames) => {
 
    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {data} = await CustomerApi.getPublicRegConsents(containersNames);
            dispatch(loadPublicRegConsentsSuccess(data));
            return data;
        } catch (error) {
            dispatch(loadPublicRegConsentsFailure(error));
            throw error;
        }
    }
 
};

const loadPublicRegConsentsSuccess = (containers) => {
    return {
        type: `${LOAD_PUBLIC_REG_CONSENTS}_SUCCESS`,
        payload: {containers}
    }
};

const loadPublicRegConsentsFailure = (error) => {
    return {
        type: `${LOAD_PUBLIC_REG_CONSENTS}_FAILURE`,
        payload: {error}
    }
};

const getMyIdStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {data} =  await CustomerApi.getMyIdStatus();
            dispatch(updateMyIdStatus(data));
        } catch (error) {
            throw error;
        }
    }
};

const updateMyIdStatus = (status) => {
    return {
        type: `${UPDATE_MYID_STATUS}_SUCCESS`,
        payload: { status }
    }
};

export {
    cashierRegisterCustomer,
    changeCustomerConsent,
    customActivateAccount,
    customerActivateAccount,
    getCustomerBankList,
    getCustomerBankListFailure,
    getCustomerBankListSuccess,
    getCustomerBettingStatus,
    getCustomerSegmentStatus,
    getMyIdStatus,
    isValueUnique,
    loadCustomerConsents,
    loadCustomerLimits,
    loadCustomerLimitsOptions,
    loadDocumentScans,
    loadDocumentTypes,
    loadGiifData,
    loadPersonalData,
    loadPersonalDataFailure,
    loadPersonalDataPending,
    loadPersonalDataSuccess,
    loadCustomerData,
    startLoadCustomerDataInterval,
    stopLoadCustomerDataInterval,
    updateCustomerConsents,
    updateCustomerLimits,
    updatePersonalData,
    uploadDocumentData,
    validateBankAccount,
    validateBankAccountFailure,
    validateBankAccountSuccess,
    validateBVNNumber,
    validateBVNNumberFailure,
    validateBVNNumberSuccess,
    kycStatusCheck,
    //loadCustomerConsentDefinition,
    createCustomerConsentDefinition,
    updateCustomerConsentDefinition,
    updateAllCustomerConsentDefinition,
    loadCustomerConsentContainers,
    loadCustomerConsentsByTagAndNames,
    isBankAccountNumberUniqueForCustomer,
    uploadCustomerAvatar,
    getCustomerAvatar,
    acceptRegulations,
    getCustomerPromotions,
    getCustomerPromotionsSuccess,
    getCustomerPromotionsFailure,
    updateCustomerPromotionSuccess,
    updateCustomerPromotionFailure,
    updateCustomerPromotion,
    loadPublicRegConsents,
    getCustomerDaysFromRegistration
}