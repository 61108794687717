import {format, fromUnixTime, subYears, subDays, compareAsc} from 'date-fns';

const formatDate = (date, pattern, config = {}) => {
    return format(date, pattern, config);
};

const formatDateFromUnix = (timestamp, pattern) => {
    return format(fromUnixTime(timestamp), pattern);
};

const substractYears = (date, years)=>{
    return subYears(date, years);
};

const substractDays = (date, days)=>{
    return subDays(date, days);
};

const getDaysDifference = (fromDate, toDate) => {
    let diffTime = Math.abs(fromDate.getTime() - toDate.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

const getTimeFilterRange = (filterType) => {
    let dateObject = {};
    let startDate = new Date();
    dateObject['endDate'] = new Date();

    switch(filterType) {
        case 'today':
            dateObject['startDate'] = startDate;
            break;
        case 'yesterday':
            dateObject['startDate'] = startDate.setDate(startDate.getDate() - 1);
            break;
        case 'week':
            dateObject['startDate'] = startDate.setDate(startDate.getDate() - 6);
            break;
        case 'month':
            dateObject['startDate'] = startDate.setDate(startDate.getDate() - 29);
            break;
        default: 
            return;
    }
    return dateObject;
}

const getPolishTimePlurality = (number) => {
    const stringNumber = number.toString();
    const stringLength = stringNumber.length;
    let plurality;

    if (stringNumber == '1') {
        plurality = 'singular'
    } else if ((stringNumber.endsWith(2) || stringNumber.endsWith(3) || stringNumber.endsWith(4)) && (stringNumber.charAt(stringLength - 2) !== '1' || stringLength === 1)) {
        plurality = 'from2To4';
    } else {
        plurality = 'plural';
    }
    
    return plurality;
}

export {formatDate, formatDateFromUnix, substractYears, substractDays, getDaysDifference, compareAsc, getTimeFilterRange, getPolishTimePlurality}