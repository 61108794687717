import styled from 'styled-components';

const S = {};

S.IntegrationGameIframe = styled.div`
    margin: 1rem; 
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;

    & > iframe {
        width: 100%;
    }
`;

S.NotLogged = styled.div`
    font-size: 1.5rem;
    color: #000;
    display: flex;
    justify-content: center;
    margin: ${({singleGame}) => {return singleGame ? 'unset' : '2rem 9% 0 0';}};
`;


export default S;
