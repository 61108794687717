import {formatDate} from 'datesHelper.js';
const encode = encodeURIComponent;
const today = new Date();

export default (requests, axios) => {
    return {
        customerActivateAccount: (data) => {
            return requests.post(`/customer/account/activate-account`, data);
        },
        getCustomerData: ({ keepSessionAlive = false, updateBalance = false }={}) => {
            if(CLIENT == 'accessbet'){
                return requests.get(`/customer/session/customerData/${keepSessionAlive}?updateBalance=${updateBalance}`)
            }  
            return requests.get(`/customer/session/customerData/${keepSessionAlive}/${updateBalance}`)
        },
        getPersonalData: () => {
            return requests.get('/customer/account/personal-data');
        },
        updatePersonalData: (data) => {
            return requests.post(`/customer/account/change-personal-data`, data);
        },
        isValueUnique: (fieldName, fieldValue) => {
            return requests.get(`/customer/account/is-value-unique/${fieldName}/${fieldValue}`);
        },
        getCustomerBettingStatus: () => {
            return requests.get(`/customer/session/betting-status`);
        },
        getCustomerSegmentStatus: () => {
            return requests.get(`/customer/account/segmentation/status`);
        },
        getCustomerLimits: () => {
            return requests.get(`/customer/account/limits/data`);
        },
        updateCustomerLimits: (data) => {
            return requests.put(`/customer/account/limits`, data);
        },
        acceptRegulations: () => {
            return requests.post(`/customer/session/accept-regulations`);
        },
        getGiifTypes: () => {
            return requests.get('/navigation/giif-types');
        },
        getDocumentScans: () => {
            return requests.get('/document/scans');
        },
        uploadDocumentScan: (data) => {
            return requests.post('/document/scans', data);
        },
        getDocumentTypes: () => {
            return requests.get('/document/type');
        },
        getVeryficationStatus: () => {
            return requests.get('/customer/verification/status');
        },
		    getCustomerConsents: () => {
            return requests.get('/customer/account/consents');
        },
        updateCustomerConsents: (data) => {
            return requests.post('/customer/account/consents', data);
        },
        getSessionHistory: ({endDate = today, startDate = today, limit = 10, currentPage = 0} = {}) => {
            const dateFrom = formatDate(startDate, 'dd-MM-yyyy');
            const dateTo = formatDate(endDate, 'dd-MM-yyyy');
            const offset =  currentPage * limit;
            return requests.get(`/customer/session/session-history/${dateFrom}/${dateTo}/?limit=${limit}&offset=${offset}`);
        },
        getValidityCustomRegisterToken: (token) => {
            return requests.get(`customer/account/custom-register/validate?token=${token}`);
        },
        cashierRegisterCustomer: (data) => {
            return requests.post('/customer/account/custom-register', data);
        },
        customActivateAccount: (data) => {
            return requests.post('/customer/account/custom-activate-account', data);
        },
        getCustomerBankList: () => {
            return requests.get(`/customer/account/smile-id/banks`);
        },
        validateBankAccount: (data) => {
            return requests.post(`/customer/account/smile-id/validate`, data);
        },
        validateBVNNumber: (data) => {
            return requests.post(`/customer/account/paystack-bvn/validate`, data);
        },
        getCustomerKycStatus:  () => {
            return requests.get(`/customer/account/kyc/status`);
        },
        getCustomerConsentContainers: (containersNames) => {
            return requests.get(`/customer/consents/containers/${containersNames}`);
        },
        createCustomerConsentDefinition: (data) => {
            return requests.post('/customer/consents/definitions', data);
        },
        updateCustomerConsentDefinition: (data) => {
            const { definitionId } = data;
            data = _.omit(data,'definitionId');
            return requests.put(`/customer/consents/definitions/${definitionId}`, data);
        },
        getCustomerConsentDefinition: (definitionId) => {
            return requests.get(`/customer/consents/definitions/${definitionId}`);
        },
        isBankAccountNumberUniqueForCustomer: (bankAccountNumber) => { 
            return requests.get(`/customer/account/bankAccountNumber/isUniqueForCustomer/${bankAccountNumber}`);
        },
        uploadCustomerAvatar: (data) => {
            return requests.post(`/customer/avatar`, data);
        },
        getCustomerAvatar: () => {
            return requests.get(`/customer/avatar`);
        },
		updateCustomerConstans:(data)=>{
            return requests.post(`/customer/consents/definitions`,data)
        },
        getCustomerPromotions:()=>{
            return requests.get(`/customer/consents/containers/PROMOTIONS`);
        },
        // generic request for new consents
        getCustomerConsentTags:({tag, names})=>{
            return requests.get(`/customer/consents/tags/${tag}?names=${names}`);
        },
        getPublicRegConsents: (containersNames) => {
            return requests.get(`/customer/consents/tags/public?names=${containersNames}`);
        },
        getMyIdStatus:()=>{
            return requests.get(`/customer/identity-resolver/my-id`)
        }
    };
};
