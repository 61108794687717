import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getVirutalGame, getSimpleIframeGame} from 'integrationActions.js';
import Loader from 'Loader.js';
import ReactDOM from 'react-dom'
import {translation} from 'helpers/utilsHelper.js';
import S from 'StyledIntegrationGameIframe.js';


class IntegrationGameIframe extends Component {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
    }

    componentDidMount = async() => {
        this.getUrlGame();
    };

    componentDidUpdate = async(prevProps, prevState) => {
        if (prevProps.isLogged !== this.props.isLogged) {
            this.getUrlGame();
        }
    }

    getUrlGame = async() => {
        const {dispatch, provider, gameId} = this.props;
        try {
            switch(provider) {
                case 'globalbet':
                    const iframeUrl = await dispatch(getSimpleIframeGame(provider));
                    this.globalBetWidgetsLoad(iframeUrl);
                    break;
                case 'elbet': 
                    this.elbetAddEventListenerResize();
                    await dispatch(getVirutalGame(provider, gameId));  
                    break;
                default:
                    console.log(`getVirutalGame: Integration for ${provider} not found!`);
            }

        } catch (error) {
            console.log(`getVirutalGame: ${error}`);
        }
    }

    globalBetWidgetsLoad = (iframeUrl) => {
        const src = process.env.GLOBALBET_WIDGETS_LOAD;

        this.iframeRef.current.height = `${$(window).height()}px`;

        if (document.querySelector(`script[src="${src}"]`) != undefined) {
            widgetAdapter.registerVirtualSports(this.iframeRef.current, iframeUrl), {sticky: true};
        } else {
            const script = document.createElement('script');
            script.onload = () => {
                widgetAdapter.registerVirtualSports(this.iframeRef.current, iframeUrl), {sticky: true};
            };

            script.src = src;
            document.head.appendChild(script);
        }
    }

    elbetAddEventListenerResize = () => {
        window.addEventListener("message", (event) => {
            if (event.data.eventName == 'game_to_iframe' && this.iframeRef && this.iframeRef.current) {
                this.iframeRef.current.height = `${event.data.response}px`;
            }
        }, false);
    }

    render() {

        const {isPending, error, gameUrl, isLogged, singleGame, mustBeLogged, providerCreateIframe} = this.props;

        if (error) {
            return <div>Error fallback component!</div>
        }

        if (isPending) {
            return <Loader/>
        }

        if (mustBeLogged) {
            if (!isLogged && !isPending ) {
                return <S.NotLogged>{translation('integration_notLogged')}</S.NotLogged>
            }
        }

        if (providerCreateIframe) {
            return (
                <S.IntegrationGameIframe ref={this.iframeRef} singleGame={singleGame}></S.IntegrationGameIframe>
            )
        } else {
            return (
                <S.IntegrationGameIframe singleGame={singleGame}>
                    <iframe 
                        ref={this.iframeRef}
                        src={gameUrl}
                        frameBorder="0"
                        scrolling="no"
                    ></iframe>
                </S.IntegrationGameIframe>
            )
        }

    }
}

const mapStateToProps = (state, props) => {

    let {IntegrationGameIframe:{isPending, error, gameUrl, iFrameHeight, provider, singleGame, mustBeLogged, providerCreateIframe}, Auth:{isLogged}} = state;

    if (provider === null) {
        provider = props.provider;
    }
    
    return {
        isPending,
        error,
        gameUrl,
        isLogged,
        iFrameHeight,
        provider,
        singleGame,
        mustBeLogged,
        providerCreateIframe
    }
};

export default connect(mapStateToProps)(IntegrationGameIframe);
