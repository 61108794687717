import _get from 'lodash/get';
import {selectOutcomes, deselectOutcomes, updateBetSlipCombinationsTypes} from 'betSlipActions.js';

const betSlipMiddleware = ({dispatch, getState}) => (next) => (action) => {

    let {type, payload} = action;
    const actionTypes = [
        'SET_PREDEFINIED_STAKE',
        'HIDE_APPROVAL_MESSAGE',
        'ADD_OUTCOME',
        'REMOVE_OUTCOME',
        'CHANGE_SLIP_STAKE',
        'CALCULATE_ODDS',
        'SET_BETSLIP_ERROR',
        'CLEAR_BETSLIP_ERROR',
        'CHANGE_SLIP_TYPE',
        'UPDATE_POSSIBLE_WIN_DATA',
        'CLEAR_BETSLIP',
        'PLACE_BETSLIP_SUCCESS',
        'PLACE_BETSLIP_PENDING',
        'PLACE_BETSLIP_FAILURE',
        'REFRESH_ODDS_BY_OUTCOME_ID',
        'REFRESH_PREMATCH_ODDS_BY_OUTCOME_ID',
        'PLACE_BETSLIP_AGAIN',
        'TOGGLE_OUTCOME_TO_BLOCK',
        'MARK_OUTCOMES_AS_IN_BLOCK',
        'UNMARK_OUTCOMES_AS_IN_BLOCK',
        'ADD_OUTCOMES_TO_BLOCK',
        'TOGGLE_BANKER_OUTCOME',
        'UPDATE_COMBINATION_TYPES',
        'CHANGE_COMBINATION_TYPE',
        'TOGGLE_ACCUMULATOR',
        'OUTCOME_UNAVAILABLE',
        'OUTCOME_CHANGED',
        'TOGGLE_FREEBET',
        'PLACE_BETSLIP_FOR_APPROVAL_SUCCESS',
        'SHOW_APPROVAL_MESSAGE',
        'REMOVE_OUTCOME_FROM_BLOCK',
        'UDPATE_EXISTING_BLOCK',
        'SET_VALID_FREEBETS',
        'UPDATE_TOTAL_BONUS_DATA',
        'UPDATE_PROPOSITION_OFFERS',
        'SET_CUSTOMER_BETSLIP_PROMOTIONS',
        'TOGGLE_PROMOTION'
    ];

    if (actionTypes.indexOf(type) != -1) {
        const {BetSlip: {activeTab, betSlips}} = getState();
        action['payload'] = _get(action, 'payload', {});
        action.payload['activeTab'] = activeTab;

        if (type == 'ADD_OUTCOME') {
            const {outcome: {outcomeId}} = payload;
            selectOutcomes([{outcomeId}]);

        } else if (type == 'REMOVE_OUTCOME') {
            const {outcomeId} = action.payload;
            deselectOutcomes([{outcomeId}]);

        } else if (type == 'CLEAR_BETSLIP') {
            const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
            deselectOutcomes(outcomesOnTab);
        }
    }

    if (type == 'CHANGE_ACTIVE_TAB') {
        const {BetSlip: {betSlips, activeTab}} = getState();
        const prevOutcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        deselectOutcomes(prevOutcomesOnTab);

        const {activeTab:nextTab} = action.payload;
        const nextOutcomesOnTab = _get(betSlips, [nextTab, 'outcomes']);
        selectOutcomes(nextOutcomesOnTab);
    }

    if(type == 'CREATE_CUSTOM_BET'){
      dispatch(updateBetSlipCombinationsTypes());
    }

    return next(action);
};

export default betSlipMiddleware;