import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import {Provider, connect} from 'react-redux';
import {MemoryRouter as Router} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {PersistGate} from 'redux-persist/integration/react';
import store, {persistor} from './store.js';
import themeDefault from 'theme.js';
import themeLight from 'themeLight.js';
import * as ComponentsLibrary from 'components.js';
import * as FavouritesActions from 'favouritesActions.js';
import * as CategoriesActions from 'categoriesActions.js';
import * as CustomerActions from 'customerActions.js';
import * as BetSlipActions from 'betSlipActions.js';
import * as QuickSearchActions from 'quickSearchActions.js';
import * as BetSlipCheckActions from 'betSlipCheckActions.js';
import * as AccountActions from 'accountActions.js';
import * as AuthActions from 'authActions.js';

let ThemeWrapperProvider = ({children, currentTheme}) => {
    const theme = Cookies.get('themeClass') ? (Cookies.get('themeClass') == 'theme-light' ? themeLight : themeDefault) : themeDefault;
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

ThemeWrapperProvider = connect(({Theme:{currentTheme}}) => {
    return {currentTheme};
})(ThemeWrapperProvider);

const initComponent = (componentName, rootId, params = {}) => {
    const Component = ComponentsLibrary[componentName];
    const WrappedComponent = (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeWrapperProvider className="theme-wrapper-provider">
                    <Router initialEntries={['/edit-profile']} initialIndex={0}>
                        <Component {...params}/>
                    </Router>
                </ThemeWrapperProvider>
            </PersistGate>
        </Provider>
    );

    ReactDOM.render(WrappedComponent, rootId);
};

const unmountComponentAtNode = (selectorsArray) => {
    const reactComponents = document.querySelectorAll([].concat(selectorsArray).join(','));
    if (reactComponents.length) {
        for(let component of reactComponents) {
            ReactDOM.unmountComponentAtNode(component);
        }
    }
};

const actions = {
    AccountActions,
    AuthActions,
    BetSlipActions,
    BetSlipCheckActions,
    CategoriesActions,
    CustomerActions,
    FavouritesActions,
    QuickSearchActions,
};

export {initComponent, unmountComponentAtNode, store, actions}
