import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVirutalGames } from 'integrationActions.js';
import Loader from 'Loader.js';
import IntegrationGameContent from 'IntegrationGameContent.js';
import IntegrationGameIframe from 'IntegrationGameIframe.js';
import { CSSTransition } from 'react-transition-group';
import S from 'StyledIntegrationGamesList.js';


class IntegrationGamesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVirtualGame: null
        };
    }

    componentDidMount = async () => {
        const { dispatch, provider } = this.props;
        try {
            await dispatch(getVirutalGames(provider, 'games_list'));

        } catch (error) {
            console.log(`getVirutalGames: ${error}`);
        }
    };

    activeVirtualGames = (gameId) => {
        this.setState({ selectedVirtualGame: gameId });
        app.util.Utils.scrollTop();
    }

    render() {

        const { isPending, error, gamesList: { games, name } } = this.props;

        if (error) {
            return <div>Error fallback component!</div>
        }

        if (isPending) {
            return <Loader />
        }

        return ( 
            <>
            <S.IntegrationGamesListTitle>{name}</S.IntegrationGamesListTitle>
            <S.IntegrationGamesList>
                
                <div>
                    {games.map((game, index) => {

                        const { game_id, name, image, provider } = game;

                        return <div key={index} onClick={() => this.activeVirtualGames(game_id, index)}>
                            <IntegrationGameContent
                                key={index}
                                gameId={game_id}
                                name={name}
                                image={image}
                                provider={provider}>
                            </IntegrationGameContent>
                        </div>

                    })}
                </div>
                <div>
                    {games.map((game, index) => {
                        const { game_id, provider } = game;
                        return <CSSTransition
                            in={this.state.selectedVirtualGame == game_id ? true : false}
                            timeout={50}
                            key={`100${index}`}
                            classNames="virgualGame-transition"
                            unmountOnExit
                            apper>
                            <IntegrationGameIframe
                                key={`1000${index}`}
                                provider={provider}
                                gameId={game_id} />
                        </CSSTransition>
                    })}
                </div>

            </S.IntegrationGamesList>
            </>
        )

    }
}


const mapStateToProps = (state, props) => {

    let { IntegrationGamesList: { isPending, error, gamesList, provider } } = state;

    return {
        isPending,
        error,
        gamesList,
        provider
    }
};

export default connect(mapStateToProps)(IntegrationGamesList);
